@media screen and (max-width: $mobile-breakpoint) {
  .quick-nav {
    &-main {
      overflow-x: auto;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
      margin: 0;
      padding: 0 1.6rem;
      .section-wrapper-sm {
        padding: 0;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-link {
      width: 100%;
      display: flex !important;
      padding: 0.9rem 0.7rem;
      justify-content: center !important;
      svg {
        width: 2rem !important;
        height: 2rem !important;
      }

      span {
        display: none !important;
      }
    }

    &-item {
      & + .quick-nav-item {
        margin: 0;
      }

      &-left {
        width: calc(25% - 24px);
      }
      &-right {
        width: 75%;
        @include flex(stretch, space-between);
        .quick-nav-item {
          width: calc(33.3333% - 16px);
        }
      }
    }
  }
  .tab-list {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  #benefit,
  #faqs {
    margin-top: 3.2rem !important;
  }

  #faqs {
    .tab-list {
      background-color: transparent;
    }

    .tab-list:not(.tab-list-1) {
      overflow-x: auto;
      width: auto;

      &::-webkit-scrollbar {
        display: none;
      }
      .tab-item + .tab-item {
        margin-left: 1.6rem;
      }
    }

    .tab-item {
      flex-shrink: 0;
      width: auto;
      min-width: 40%;
    }
  }
  .benefit {

    &-content {
      padding: 0;
      margin-top: 0 !important;
    }

    &-item {
      width: unset !important;
      margin-top: 2.4rem;
    }

    &-image {
      height: 245px !important;
      width: auto !important;
      margin-bottom: 1.6rem !important;

      .benefit-image-main {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-text {
      margin-bottom: 0.8rem !important;
      font-size: 2rem;
    }

    &-desc {
      p {
        font-size: 1.4rem !important;
      }
    }
  }

  .custom-accordion-title {
    font-size: 1.4rem !important;
  }
  nav {
    margin: 0 auto;
  }
  #merchant-detail,
  #trading-index {
    thead {
      display: none;
    }

    table.custom-table {
      tr {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid $border-color;

        &:not(:last-child) {
          margin-bottom: 1.6rem;
        }
      }

      td {
        width: 50%;
        border-bottom: 0;
        padding: 0;
        margin-bottom: 1.6rem;

        &:nth-child(even) {
          @include flex(flex-start, center, column);
        }

        &:nth-child(odd) {
          @include flex(center, flex-end);
        }
      }

      td:first-child {
        padding-left: 0;
        width: 100%;
        justify-content: space-between;

        & > div {
          width: 100%;
        }

        .image-circle {
          & + div {
            flex: 1;
            justify-content: space-between;
            align-items: center;
            display: flex;
          }
        }
      }

      td:last-child {
        display: none;
      }
    }
  }
  #quickBuySellBtn {
    bottom: $padding-mobile;
    right: $padding-mobile;
  }
  .quickBuySellBtn {
    @include size(4.5rem);
  }
  .refresh-item {
    padding: 0.8rem 1.2rem;
    height: 4rem;
    border: 1px solid transparent;
    border-radius: 0.4rem;
    &.active {
      border-color: var(--bs-dark);
      span {
        color: var(--bs-dark);
      }
    }
    & + .refresh-item {
      margin-top: 0.8rem;
    }
  }
  .tab-wrapper {
    flex-direction: row !important;
  }
}

@media screen and (max-width: $desktop-breakpoint){
  .pattern {
    display: none;
  }
}
@media (min-width: 900px) and (max-width: 1440px) {
  #trading-index {
    .tab-list {
      width: fit-content;
      margin: 0 auto 2.4rem;
    }
  }
}
@media (min-width: 900px) and (max-width: $desktop-breakpoint){
  #TheSellModal,
  #TheBuyModal {
    .custom-modal {
      .modal-content {
        max-width: calc(100vw - 8rem);
      }
    }
  }
  #faqs {
    max-width: unset;
  }
}