#navigation-bar {
  background-color: #212b40;
  position: relative;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  opacity: 1;
  &.isHide {
    position: fixed;
    transform: translateY(-100%);
    top:0;
    left: 0;
    width: 100%;
    opacity: 0;
  }
}
.navigation-bar {
  &-content {
    height: 3.5rem;
    color: $gray-light-40;
    @include flex(center, center);
  }
}
