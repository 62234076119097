@media screen and (max-width: $mobile-breakpoint) {
  #footer .c-dropdown .dropdown-menu {
    left: unset !important;
  }
  .socials {
    margin-top: 2.4rem;
  }
  .footer-col {
    width: 100%;
    margin-top: 2.4rem;

  }
  .setting-footer {
    margin-top: 2.4rem;

    .dropdown-language {
      margin-left: 0 !important;
    }
  }
}