@media screen and (max-width: $mobile-breakpoint) {
  #adv-detail {
    .adv-detail {
      &-title {
        font-size: 1.8rem !important;
      }
      &-content {
        width: 100%;
        &-left {
          padding-right: 0;
        }
        &-right {
          padding-left: 0;
          .card {
            border-bottom: 0;
            border-left: 0;
            border-right: 0;
            border-radius: 0;
            padding: 2.4rem 0 0 0;
          }
        }
      }
    }
  }
  .image-circle-sm {
    @include size(3.2rem);
    img {
      object-fit: cover;
    }
  }
}