.custom-toast {
  position: fixed;
  z-index: 99999999999;
  border: 2px solid transparent;
  padding: 2rem;
  box-shadow: 0 8px 64px rgba(43, 50, 59, 0.1);
  border-radius: 12px;
  min-width: 55.8rem;
  min-height: 8.8rem;
  opacity: 0;
  @include flex(center, space-between);
  max-width: 100%;

  &.bottom-right {
    bottom: 4rem;
    right: 4rem;
    transform: translateX(110%);

    &.isShow {
      -webkit-animation: 0.3s showRight 0.25s ease-in-out forwards, 0.3s hideRight 2s ease-in-out forwards;
      -moz-animation: 0.3s showRight 0.25s ease-in-out forwards, 0.3s hideRight 2s ease-in-out forwards;
      -o-animation: 0.3s showRight 0.25s ease-in-out forwards, 0.3s hideRight 2s ease-in-out forwards;
      animation: 0.3s showRight 0.25s ease-in-out forwards, 0.3s hideRight 2s ease-in-out forwards;
    }
  }

  &.bottom-left {
    bottom: 4rem;
    left: 4rem;
    transform: translateX(-110%);

    &.isShow {
      -webkit-animation: 0.3s showLeft 0.25s ease-in-out forwards, 0.3s hideLeft 2s ease-in-out forwards;
      -moz-animation: 0.3s showLeft 0.25s ease-in-out forwards, 0.3s hideLeft 2s ease-in-out forwards;
      -o-animation: 0.3s showLeft 0.25s ease-in-out forwards, 0.3s hideLeft 2s ease-in-out forwards;
      animation: 0.3s showLeft 0.25s ease-in-out forwards, 0.3s hideLeft 2s ease-in-out forwards;
    }
  }

  &.top-left {
    top: 4rem;
    left: 4rem;
    transform: translateX(-110%);

    &.isShow {
      -webkit-animation: 0.3s showLeft 0.25s ease-in-out forwards, 0.3s hideLeft 2s ease-in-out forwards;
      -moz-animation: 0.3s showLeft 0.25s ease-in-out forwards, 0.3s hideLeft 2s ease-in-out forwards;
      -o-animation: 0.3s showLeft 0.25s ease-in-out forwards, 0.3s hideLeft 2s ease-in-out forwards;
      animation: 0.3s showLeft 0.25s ease-in-out forwards, 0.3s hideLeft 2s ease-in-out forwards;
    }
  }

  &.top-right {
    top: 4rem;
    right: 4rem;
    &.isShow {
      -webkit-animation: 0.3s showRight 0.25s ease-in-out forwards, 0.3s hideRight 2s ease-in-out forwards;
      -moz-animation: 0.3s showRight 0.25s ease-in-out forwards, 0.3s hideRight 2s ease-in-out forwards;
      -o-animation: 0.3s showRight 0.25s ease-in-out forwards, 0.3s hideRight 2s ease-in-out forwards;
      animation: 0.3s showRight 0.25s ease-in-out forwards, 0.3s hideRight 2s ease-in-out forwards;
    }
  }

  &.toast-warning {
    border-color: $c-toast-warning;
    background-color: $c-toast-warning-bg;

    .toast-icon {
      background-color: $c-toast-warning-icon;
    }

    .toast-message {
      color: $c-toast-warning-text;
    }

    .close-toast-btn {
      cursor: pointer;

      svg path {
        stroke: $c-toast-warning-text !important;
      }
    }
  }

  &.toast-success {
    border-color: $c-toast-success;
    background-color: $c-toast-success-bg;

    .toast-icon {
      background-color: $c-toast-success-icon;
    }

    .toast-message {
      color: $c-toast-success-text;
    }

    .close-toast-btn {
      cursor: pointer;

      svg path {
        stroke: $c-toast-success-text !important;
      }
    }
  }

  &.toast-info {
    border-color: $c-toast-info;
    background-color: $c-toast-info-bg;

    .toast-icon {
      background-color: $c-toast-info-icon;
    }

    .toast-message {
      color: $c-toast-info-text;
    }

    .close-toast-btn {
      cursor: pointer;

      svg path {
        stroke: $c-toast-info-text !important;
      }
    }
  }

  &.toast-error {
    border-color: $c-toast-error;
    background-color: $c-toast-error-bg;

    .toast-icon {
      background-color: $c-toast-error-icon;
    }

    .toast-message {
      color: $c-toast-error-text;
    }

    .close-toast-btn {
      cursor: pointer;

      svg path {
        stroke: $c-toast-error-text !important;
      }
    }
  }
}

.toast-content {
  @include flex(center, flex-start);
}

.toast-message {
  font-weight: 600;
  font-size: 16px;
}

.toast-icon {
  @include size(48px);
  border-radius: 50%;
  @include flex(center, center);
  margin-right: 1.2rem;
}

@keyframes showLeft {
  from {
    opacity: 0;
    transform: translateX(-110%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }

}
@keyframes showRight {
  from {
    opacity: 0;
    transform: translateX(110%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }

}

@keyframes hideLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-110%);
  }

}
@keyframes hideRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(110%);
  }

}
