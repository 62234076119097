// Grid layouts

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 900px,
        lg: 1025px,
        xl: 1240px,
        xxl: 1600px,
);

$container-max-widths: (
        sm: 540px,
        md: 790px,
        lg: 1140px,
        xl: 1280px,
        xxl: 1440px,
);

$content-wrap-max-widths: (
        sm: 540px,
        md: 790px,
        lg: 1140px,
        xl: 1280px,
        xxl: 1280px,
);

// Typography

$font-family-base: "Inter";
$font-size-root: 14px;
$font-size-base: 1.4rem;

// Color
$body-bg: #F8F9FC;
$body-color: #758499;
$body-dark: #2B323B;
$primary: #355DFF;
$secondary: #9FA6B2;
$info: #3B82F6;
$success: #10B981;
$warning: #F5D90A;
$danger: #EF5B44;
$dark: #01102F;
$darker: #181B21;
$light: #F4F5F7;
$lighter: #F9FAFB;
$gray-light: #E6E9EC;
$gray-light-40: #B6BEC9;
$orange: #FB8134;
$light-orange:  #F88371;
$c-gray: #38414C;
$c-light: #A0A0A0;
$gray: #B6BEC9;
$yellow: #FBBF24;
$dark-blue: #01102F;
$light-blue:  #1E3A8A;

$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "lighter": $lighter,
        "dark": $dark,
        "darker": $darker,
        "orange": $orange,
        "light-orange": $light-orange,
        "c-gray": $c-gray,
        "gray": $gray,
        "c-light": $c-light,
        "body-dark": $body-dark,
        "yellow": $yellow,
        "dark-blue": $dark-blue,
        "gray-light": $gray-light,
        "gray-light-40": $gray-light-40,
        'light-blue': $light-blue
);

$nav-link-color: $secondary;
$nav-link-hover-color: $primary;
// $dropdown-link-active-color: white;

// Components

$border-radius: 5px;

$btn-focus-width: 0px;
$btn-focus-box-shadow: none;
$btn-border-radius: 0px;
$btn-border-radius-sm: 0px;
$btn-border-radius-lg: 0px;
$btn-padding-y: 0.25em;
$btn-padding-x: 1.25em;
$btn-padding-y-sm: $btn-padding-y;
$btn-padding-x-sm: $btn-padding-x * 0.8;
$btn-padding-y-lg: $btn-padding-y;
$btn-padding-x-lg: $btn-padding-x;

// $input-border-radius: 0px;
// $input-border-radius-sm: 0px;
// $input-border-radius-lg: 0px;
$input-border-color: #CFD0D3;
$input-focus-border-color: #9b9c9f;
$input-padding-y: 0.8rem;
$input-padding-x: 1.15rem;


$pagination-color: $dark;
$pagination-focus-box-shadow: none;

$box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .08);
$box-shadow-sm: 0 .25rem .25rem rgba(0, 0, 0, .02);

$border-radius-lg: 0.75rem;
$nav-link-color: var(--bs-body-color);

$accordion-bg: #fff;
$accordion-button-active-bg: transparent;

$dropdown-min-width: 18rem;
