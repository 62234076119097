#maintenance {
  min-height: calc(100vh - (64px + 80px));
}
#error-404 {
  min-height: calc(100vh - (64px + 130px));
}
.error-body,
.maintenance-body {
  padding: 1.6rem;
}

@media screen and (max-width: $mobile-breakpoint) {
  .error-image,
  .maintenance-image {
    width: 21.8rem;
    img {
      width: 100%;
    }
  }
}