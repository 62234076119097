@media screen and (max-width: $mobile-breakpoint) {
  .profile {
    &-intro {
      .user-avatar {
        @include size(12rem);
      }
    }
    &-body {
      .pagination {
        .page-item {
          &:first-child,
          &:last-child {
            position: relative;
          }
        }
      }
      .custom-tab-2 {
        height: 4rem;
      }
    }
  }
  .profile-name {
    font-size: 2.8rem !important;
  }
  .section-cover {
    aspect-ratio: 16/7;
    height: auto;
  }
  .custom-tab-2 {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    margin: 0 -1.6rem;
    padding: 0 $padding-mobile;

    .quick-nav-link {
      color: var(--bs-body-color);
      &.active {
        color: var(--bs-dark);
      }

      &:hover {
        color: var(--bs-dark);
      }
    }
  }
  //.activity-item {
  //  min-height: 7.6rem;
  //}
  #profile {
    .custom-tab .nav-link {
      padding: 1.2rem 0;
    }

    .overlay {
      &.isShow {
        display: block;
      }
    }
  }
  .review-item {
    min-height: 8.4rem;
    .image-circle {
      @include size(3.2rem);
      margin-right: 1.2rem !important;
      img {
        object-fit: cover;
      }
    }
  }
  .qr-code-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: calc(100vw - 32px);
    background-color: $body-background;
    transform: translate(-50%, -60%);
    max-height: 32.8rem;
    box-shadow: 0 12px 64px rgba(0, 0, 0, 0.1);
    border-radius: 1.2rem;
    padding: $padding-mobile 2.4rem;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;

    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    display: none;
    z-index: 9999;
    opacity: 0;
    &.isShow {
      display: flex;
      animation: showQrCode 0.25s ease-in-out forwards;
    }
  }
  .qr-code-btn {
    margin-left: $padding-mobile;
  }
  @keyframes showQrCode {
    from {
      opacity: 0;
      transform: translate(-50%, -60%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

  }

  #merchant-detail {
    table.custom-table tr, #trading-index table.custom-table tr {
      border-bottom: 0;
      border-top: 1px solid $border-color;
      padding-top: 1.6rem;
    }
    tr.no-border-end {
      border: 0;
      td {
        border: 0 !important;
      }
    }
    .image-circle-lg {
      @include size(3.2rem);
      img {
        object-fit: cover;
      }
    }
    .section-intro {
      height: auto;
      padding: $padding-mobile 0 4rem;
    }
    .merchant-detail {
      &-info {
        margin: $padding-mobile 0;
      }
      &-highlight {
        padding-top: $padding-mobile;
        padding-bottom: $padding-mobile;
        margin-top: $padding-mobile;
        margin-bottom: $padding-mobile;
        border-top: 1px solid $border-dark;
        border-bottom: 1px solid $border-dark;
      }
      &-highlight-item {
        padding: 0;
        background-color: transparent !important;
        @include flex(center, space-between, row-reverse);
      }
      &-label {
        color: $c-gray-40 !important;
        font-size: 1.2rem;
      }
      &-value {
        color: #FDFDFD !important;
        font-size: 1.2rem;
      }
      &-body {
        padding-top: 0;
      }
    }
    .tab-list {
      width: 100%;
    }
  }
}
@media screen and (min-width: 900px){
  #merchant-detail {
    table.custom-table tr td:not(:last-child) {
      width: 18%;
    }
  }
}