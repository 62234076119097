.c-form {
  &-group {
    & + .c-form-group {
      margin-top: 2.4rem;
    }

    &-large {
      min-width: 41rem;
    }

    textarea {
      padding: 2rem;
      border: 1px solid $border-color !important;
      background-color: transparent !important;
      outline: 0 !important;
      width: 100%;
      border-radius: 0.8rem;
      &:focus {
        outline: 1px solid var(--bs-dark) !important;
        box-shadow: unset;
      }
      &::placeholder {
        color: #B6BEC9;
      }
    }
    .input-wrapper {
      border: 0;
      input {
        border: 1px solid $border-color;
        background-color: transparent;

        &::placeholder {
          color: #B6BEC9;
        }
      }
    }
    .input-group-custom {
      border: 0;
      input {
        border: 1px solid $border-color;
        background-color: transparent;

        &::placeholder {
          color: #B6BEC9;
        }
      }
    }

    &.invalid {
      .input-wrapper {
        input {
          border-color: var(--bs-danger);
        }
      }
      .input-group-custom {
        input {
          border-color: var(--bs-danger);
        }
      }

      .error {
        display: inline-block;
        color: var(--bs-danger);
        font-size: 1.4rem;
        margin-top: 0.5rem;
      }
    }

    .input-wrapper {
      position: relative;

      input {
        position: absolute;
        background-color: transparent;
        @include size(100%);
        padding-right: 28%;
      }
      input.c-invalid,
      input:active,
      input:focus {
        border: 1px solid var(--bs-dark);
      }
    }
    .input-group-custom {
      width: 100%;
      height: 4.6rem;
      input {
        background-color: transparent;
        //@include size(100%);
        width: 77%;
        //padding-right: 28%;
      }
      input.c-invalid,
      input:active,
      input:focus {
        border: 1px solid var(--bs-dark);
      }
    }
  }
}

.input-wrapper-lg {
  .dropdown-toggle {
    height: 100%;
    border: 0;

    &:hover {
      border: 0;
    }
  }

  .dropdown-menu {
    width: 100%;
  }
}
.input-group-custom-lg {
  .dropdown-toggle {
    height: 100%;
    border-radius: 0.8rem;
    border: 1px solid #E6E9EC;
    color: var(--bs-btn-hover-color);
    &:hover {
      background: transparent;
      border: 1px solid var(--bs-dark);
    }
    &.show {
      background: transparent;
    }
  }

  .dropdown-menu {
    width: 100%;
  }
}


.error {
  display: none;
}

.input-action,
.input-action-text {
  cursor: pointer;
  z-index: 9999;
}

.edit-form {
  width: 58.8rem;
  max-width: 100%;
}

.input-quantity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: 1px solid $border-color !important;

  .decrease,
  .increase {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include size(32px);
    @include flex(center, center);
    border: 0;
    background-color: transparent;
    z-index: 9999;
  }

  input {
    width: auto !important
  }

  .decrease {
    left: 2rem;
  }

  .increase {
    right: 2rem;
  }
}


.input-sizer {
  display: inline-grid;
  vertical-align: top;
  align-items: center;
  position: relative;
  padding: 0 !important;

  &-label {
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--bs-dark);
    display: block;
    margin-left: 1rem;
    &-2 {
      margin-left: -2rem !important;
    }
  }

  &.hasValue {
    &::after,
    input,
    textarea {
      min-width: 5.2em;
    }
  }
  &.stacked {
    align-items: stretch;

    &::after,
    input,
    textarea {
      grid-area: 2 / 1;
    }
  }

  &::after,
  input,
  textarea {
    width: auto;
    min-width: 1em;
    grid-area: 1 / 2;
    margin: 0;
    resize: none;
    background: none;
    appearance: none;
    border: none !important;
    text-align: center;
    padding: 0 !important;
    font-size: 1.6rem;
    font-weight: 500;
    left: 50%;
    transform: translateX(-50%);
    color: var(--bs-dark);
  }

  span {
    padding: 0.25em;
  }

  &::after {
    content: attr(data-value) ' ';
    visibility: hidden;
    white-space: pre-wrap;
  }

  &:focus-within {

    > span {
      color: blue;
    }

    textarea:focus,
    input:focus {
      outline: none;
    }
  }
}

.input-option {
  border: 1px solid $border-color !important;
  padding: 0 2rem;
  border-radius: $border-radius;
  .option-item {
    margin-right: 2rem;
  }
  & + .input-option {
    margin-top: 1.2rem;
  }
}
.form {
  &-check {
    margin: 0;
    padding: 0;
    position: relative;
    input[type='checkbox'] {
      @include size(22px);
      border-radius: 8px !important;
      margin: 0;
      padding: 0;
      outline: 0;
      cursor: pointer;
      opacity: 0;
      &:focus {
        box-shadow: unset !important;
      }
    }
    .checkmark {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      @include flex(center, center);
    }
  }
}

.highlight-input {
  display: inline-block;
  min-width: 12rem;
  text-align: center;
  border: 0;
  outline: 0;
  border-bottom: 1px solid $border-color;
}

.c-form-check {
  position: relative;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  &-2 {
    input[type=radio]:checked {
      position: relative;
      & + .c-form-label {
        span {
          color: var(--bs-primary) !important;
        }
      }
    }
  }
  input[type=radio] {
    @include size(20px);
    flex-shrink: 0;

    & ~ .checkmark {
      display: none;
    }
  }

  input[type=radio]:checked {
    position: relative;
    & + .c-form-label {
      span {
        color: var(--bs-dark) !important;
      }
    }
    & ~ .checkmark {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      @include size(20px);
      border-radius: 50%;
      background-color: var(--bs-primary);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff !important;
      font-weight: 800;
      &::after {
        position: absolute;
        content: '';
        @include size(80%);
        background-color: #fff;
        border-radius: 50%;
      }
      &::before {
        position: absolute;
        content: '';
        @include size(50%);
        background-color: var(--bs-primary);
        border-radius: 50%;
        z-index: 99;
      }
    }
  }

  &.active {
    border-color: var(--bs-primary) !important;
  }

  &:not(.address-wallet):hover {

  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

}

.c-invalid {
  border-color: var(--bs-danger) !important;
}
.input-small {
  font-size: 1.4rem !important;
  &::placeholder {
    font-size: 1.4rem !important;
  }
}
.textarea-rounded {
  border-radius: 1.2rem;
}
.input-code {
  text-align: left;
  letter-spacing: 2rem;
  padding: 0 1.6rem !important;
}
.send-code-btn {
  position: absolute;
  right: 2.4rem;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
  padding: 0.5rem 0;
  &-mobile {
    display: none;
  }
}
