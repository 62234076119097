html {
  font-size: 62.5% !important;
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
body {
  font-weight: 500;
  background-color: $body-background !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

input {
  background-color: transparent;
  &::placeholder {
    color: #B6BEC9;
    font-weight: 500 !important;
  }
}
*:focus {
  outline: none;
}
img {
  object-fit: cover;
}