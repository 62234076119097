@media screen and (max-width: $mobile-breakpoint) {
  #login-view {
    .c-dropdown {
      .dropdown-menu {
        z-index: 99999;
      }
    }

    .section-wrapper-sm {
      padding: 0;
    }

    .login-view-header {
      padding: $padding-mobile;
    }

    .login-form-wrapper {
      position: relative;
      transform: unset;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 0;
      padding: 4rem 1.6rem;
      min-height: calc(100vh - 64px);
    }

    .login-link {
      width: 100%;
      justify-content: center;
      min-height: 4.8rem;
    }

    .login-heading-check {
      width: 75%;
    }
    .c-form-group .input-wrapper input {
      padding-right: 1.6rem;
    }
    .c-form-group .input-wrapper.input-password input {
      padding-right: 6rem;
    }
  }
  #The2FAModal {
    .modal-content {
      position: relative;
      transform: translate(0);
      top: 6.4rem;
      left: 0;
      width: 100%;
      min-height: calc(100vh - 64px);
      max-width: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      -webkit-animation: showModal 0.3s ease-in-out forwards;
      -moz-animation: showModal 0.3s ease-in-out forwards;
      -ms-animation: showModal 0.3s ease-in-out forwards;
      -o-animation: showModal 0.3s ease-in-out forwards;
      animation: showModal 0.3s ease-in-out forwards;
      height: 100%;
      padding-bottom: 14rem;

      form {
        @include flex(center, space-between, column);
        height: 100%;

        div {
          width: 100%;
        }
      }
    }

    .modal-action {
      width: 100%;
      margin-top: auto !important;
    }
  }
  .send-code-btn {
    display: none;

    &-mobile {
      display: block;
      position: relative;
      transform: translate(0);
      top: unset;
      right: unset;
      width: 100%;
      @include flex(center, center);
      margin-top: 1.6rem;
    }
  }

}