@mixin  size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin flex($align: center, $justify: space-between, $direction: row, $wrap: nowrap) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
  flex-wrap: $wrap;
}

@mixin centerAbsolute($direction: "both") {
  position: absolute;
  @if($direction == "both") {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  } @else if($direction == "left") {
    left: 50%;
    transform: translateX(-50%);
  } @else {
    top: 50%;
    transform: translateY(-50%);
  }
}

@mixin image($width, $height, $rounded: fasle) {
  width: $width;
  height: $height;
  @if ($rounded) {
    border-radius: 100rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}