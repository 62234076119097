#filter-desktop {
  .c-form-group .input-wrapper input {
    font-size: 1.4rem;
    &::placeholder {
      font-size: 1.4rem;
    }
  }
}

.dropdown-menu {
  .method-item:first-child {
    &::after {
      background-color: var(--bs-primary);
    }
  }
  .method-item {
    button {
      padding-left: 3rem !important;
    }
  }
}
.status-item {
  &-order-ALL {
    color: var(--bs-dark);
    &.active {
      .c-dropdown-title {
        color: var(--bs-dark) !important;
      }
    }
  }
  &-order-1 {
    .highlight-circle {
      background-color: $c-gray-40;
    }
  }
  &-order-2,
  &-order-4 {
    .highlight-circle {
      background-color: $success;
    }
  }

  &-order-CANCELLED {
    .highlight-circle {
      background-color: #F88371;
    }
  }
  &-order-APPEALING {
    .highlight-circle {
      background-color: #FBBF24;
    }
  }
}
.show-status {
  &-1 {
    .highlight-circle {
      background-color: $c-gray-40 !important;
    }
  }
  &-2,
  &-4 {
    .highlight-circle {
      background-color: $success !important;
    }
  }
  &-CANCELLED {
    .highlight-circle {
      background-color: #F88371 !important;
    }
  }
  &-APPEALING {
    .highlight-circle {
      background-color: #FBBF24 !important;
    }
  }
}
.show-status,
.status-item,
.status-online {
  .highlight-circle {
    background-color: $success !important;
  }
}
.filter {
  &-search {
    input {
      &:focus {
        outline: 1px solid var(--bs-dark);
      }
    }
  }
  &-item {
    width: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.filter-amount {
      //width: 33.6rem;
      width: 28rem;
    }
    &.filter-sort {
      width: auto;
    }
    &-time {
      width: 28rem;
      max-width: 100%;
    }
    & + .filter-item {
      margin-left: 1.6rem;
    }
    .input-wrapper {
      height: 4rem;
    }
  }
  &-action {
    width: 25.6rem;
    margin-left: 1.6rem;
    .c-btn {
      width: calc(50% - 8px);
      padding: 0;
    }
  }
}
