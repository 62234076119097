@media screen and (max-width: $mobile-breakpoint) {
  .highlight-input {
    width: 10rem;
    max-width: 100%;
    min-width: unset !important;
  }
  .input-wrapper-xxl .c-dropdown .dropdown-toggle,
  .input-wrapper-xxl,
  .input-wrapper-lg {
    height: 4.8rem !important;

    input {
      font-size: 1.4rem;

      &::placeholder {
        font-size: 1.4rem;
      }
    }
  }
  .c-dropdown-filter-large .dropdown-toggle {
    height: 4.8rem !important;
  }
  .input-sizer-label {
    display: none;
  }
  .input-sizer-label,
  .input-sizer::after, .input-sizer input, .input-sizer textarea {
    font-size: 1.6rem;
  }
  .input-quantity .decrease {
    left: $padding-mobile;
  }
  .input-quantity .increase {
    right: $padding-mobile;
  }
  .input-quantity .decrease, .input-quantity .increase {
    @include size(2.4rem);
    padding: 0;
    img {
      @include size(100%)
    }
  }

  .form-tab-item {
    font-size: 1.4rem;
    border-bottom-width: 2px;
  }

  .option-list {
    flex-direction: column;
    align-items: flex-start !important;

    & > label {
      border: 1px solid transparent;
      width: 100%;
      padding: 0.9rem 0.8rem;
      border-radius: $border-radius;
    }

    label.active {
      border-color: var(--bs-dark);
    }
  }
  .c-form-group .input-wrapper-action-sm input {
    padding-right: 56.5%;
  }
  .edit-form {
    width: 100%;
  }
}