@import "@/assets/scss/_variables.scss";
// FONT, ICONS
//@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

@import '../fonts/inter/inter';
@import "../icons/gg-icon";
@import "../icons/ionicon";

// GLOBAL STYLE
@import "./global/style";

// VENDORS
@import '~@marcoschulte/vue3-progress/dist';
@import '~vue-skeletor/dist/vue-skeletor.css';
@import '~aos/dist/aos.css';

// PAGE STYLE
@import "./page/style";

// COMPONENT STYLE
@import "./component/style";

// RESPONSIVE
@import "./mobile/style";

@import '~vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css';
