.section-wrapper {
  max-width: $desktop-container;
  margin: 0 auto;
  padding: 0 4rem;
  &-dark {
    padding: 8rem 0;
  }
  &-sm {
    max-width: $desktop-container-sm;
    margin: 0 auto;
  }
}
.section {
  &-intro {
    height: 25rem;
    padding-top: 4.4rem;
    padding-bottom: 8.4rem;
  }
}
.block {
  padding: 2.4rem;
  box-shadow: 0px 20px 72px rgba(43, 50, 59, 0.05);
  border-radius: 2.4rem;
}

@media (min-width: 900px) and (max-width: $tablet-breakpoint){
  .section-wrapper-sm {
    padding: 0 4rem;
  }
}
@media screen and (max-width: $mobile-breakpoint){
  .section-wrapper {
    padding: 0 $padding-mobile;
    &-sm {
      padding: 0 $padding-mobile;
    }
  }
}
@media (min-width: 900px) and (max-width: $desktop-breakpoint){
  .section-wrapper-sm {
    padding: 0;
    width: $tablet-container-sm;
    max-width: calc(100vw - 80px);
  }
}