.card {
  &-row {
    @include flex(stretch, flex-start);
    line-height: 1.6rem;
    max-height: 16px;
    & + .card-row {
      margin-top: 0.4rem;
    }
    &-title {
      padding: 1.6rem 2.6rem;
      border-radius: $border-radius;
    }
    &-body {
      padding: 2.4rem;
      border-bottom: 1px solid $border-color;
    }
    &-line {
      & + .card-row-line {
        padding-top: 1.6rem;
        margin-top: 1.6rem;
        border-top: 1px solid $border-color;
      }
    }
  }
  &-col {
    width: 16%;
    padding-right: 2rem;
    display: flex;
    align-items: center;
    &-sm {
      width: 8%;
      margin-left: auto;
      display: flex;
      align-items: center;
    }
    &-md {
      width: 10%;
      display: flex;
      align-items: center;
    }
    &-xl {
      width: 18%;
      display: flex;
      align-items: center;
      &:not(:last-child) {
        padding-right: 2rem;
      }
    }
    &-xxl {
      width: 25%;
      display: flex;
      align-items: center;
      &:not(:last-child) {
        padding-right: 2rem;
      }
    }
  }
}

.header-sub {
  background-color: var(--bs-dark);
  height: $header-sub-height;
}

.ad {
  &-item-1 {
    width: calc(50% - 12px);
    display: block !important;
    border: 1px solid $border-color;
    padding: 2.4rem;
    border-radius: 2.4rem;
    margin-bottom: 2.4rem;
  }
}

#edit-a4v {
  .title-center {
    transform: translateX(-30%);
  }
  .edit-summary {
    @include flex(stretch, center);
    width: 89.4rem;
    max-width: 100%;
    height: 8.4rem;
    &-item {
      padding: 1.6rem 2.4rem;
      border: 1px solid $border-color;
      border-radius: $border-radius;
      width: calc(33.3333% - 16px);
      margin: 0 1rem;
    }
  }
}

.steps-bar {
  @include flex(center, space-between);
  padding: 0 5rem;
}
.step {
  &-number {
    width: 4rem;
    flex-shrink: 0;
    position: relative;
    background-color: $border-color;
    &.active {
      background-color: var(--bs-primary);
      .step-title {
        color: var(--bs-dark)
      }
    }
  }
  &-line {
    height: 4px;
    width: calc(50% - 70px);
    background-color: #F4F5F7;
    &.active {
      background-color: var(--bs-primary);
    }
  }
  &-title {
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    color: $c-gray-40;
  }
}
.create-post-form {
  width: 63.6rem;
  max-width: 100%;
  margin: 8rem auto 7rem;
  border: 1px solid $border-color;
  border-radius: 1.2rem;
  padding: 2.4rem;
  .c-form-group textarea {
    border-radius: $border-radius;
  }
}
.form-tab {
  &:hover {
    .form-tab-item.active {
      color: var(--bs-body-color);
      border-color: transparent;
    }
  }
  &-item {
    border-bottom: 4px solid transparent;
    cursor: pointer;
    padding: 1.6rem;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;

    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    &.form-tab-item-buy {
      &:hover,
      &.active {
        color: var(--bs-dark);
        border-color: var(--bs-primary);
      }
      &.active {
        &:hover {
          user-select: none;
          cursor: unset;
          color: var(--bs-dark) !important;
          border-color: var(--bs-primary) !important;
        }
      }
    }
    &.form-tab-item-sell {
      &:hover,
      &.active {
        color: var(--bs-dark);
        border-color: var(--bs-orange);
      }
      &.active {
        &:hover {
          user-select: none;
          cursor: unset;
          color: var(--bs-dark) !important;
          border-color: var(--bs-orange) !important;
        }
      }
    }
  }
}

.asset-option {
  margin-right: 3.2rem;
  margin-bottom: 1.6rem;
}

.payment-method {
  &-option {
    padding: 2rem;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    width: fit-content;
    cursor: pointer;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;

    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    margin-top: 1.2rem;
    margin-right: 1.2rem;
    &.payment-method-option-2 {
      cursor: unset;
      &:hover {
        border-color: $border-color;
      }
      & > * {
        user-select: auto;
        pointer-events: auto;
      }
    }
    & > * {
      user-select: none;
      pointer-events: none;
    }
    &:hover {
      border-color: var(--bs-dark);
    }
    &.isChosen {
      border-color: var(--bs-dark);
    }
  }
  &-name {
    color: var(--bs-dark);
    padding-left: 0.8rem;
  }
}
.fiat-option .custom-control-input:disabled + span {
  text-decoration: line-through;
}
.back-btn {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.order-by-adv-detail {
  position: relative;
  &-col {
    width: 33.3333%;
    &:first-child {
      padding-right: 4rem;
    }
    &:nth-child(2) {
      padding-right: 4rem;
      padding-left: 4rem;
    }
    &:last-child {
      padding-left: 4rem;
    }
  }
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 33.3333%;
    @include size(1px, 100%);
    background-color: $border-color;
  }
  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 66.6666%;
    @include size(1px, 100%);
    background-color: $border-color;
  }
}
#TheListOrderByAdv {
  .no-data-sm {
    min-height: 17.8rem;
    margin-top: 0;
  }
}
.modal-buy-with-amount {
  .vue-skeletor--text {
    height: 1.2rem;
  }
  .vue-skeletor--pill, .vue-skeletor--text {
    border-radius: 0.4rem;
  }
}
