.coin-image {
    &-1,
    &-2 {
        position: absolute;
    }

    &-1 {
        left: 66px;
        top: 6.5rem;
    }

    &-2 {
        left: 270px;
        top: 5.5rem;
    }
}

.trading {
    &-body {
        border-top-left-radius: 4.8rem;
        border-top-right-radius: 4.8rem;
        z-index: 99;
        position: relative;

        .pattern {
            position: absolute;

            &1 {
                right: 0;
                top: 69%
            }

            &2 {
                left: 0;
                top: 72%;
                transform: rotate(180deg);
            }
        }
    }
}

.tab {
    &-list {
        min-width: 20rem;
        background-color: var(--bs-light);
        border-radius: $border-radius;
        @include flex(center, space-between);

        &-md {
            .tab-item {
                min-width: 9rem;
            }
        }

        &-1 {
            width: 195px;
            user-select: none;
            pointer-events: none;

            .tab-item {
                width: 100%;
                background-color: var(--bs-primary) !important;
            }
        }

        &:hover {
            .tab-item {
                &.active-all,
                &.active-2,
                &.active {
                    color: var(--bs-body-color);
                    background-color: transparent;
                }
            }

            .tab-item-2 {
                &.active {
                    color: var(--bs-body-color) !important;
                    background-color: transparent !important;
                }
            }
        }
    }

    &-item {
        color: var(--bs-body-color);
        width: 50%;
        padding: 1.2rem;
        height: 4rem;
        border-radius: $border-radius;
        text-align: center;
        font-weight: 600;
        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;

        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
        cursor: pointer;
        @include flex(center, center);

        &.tab-all {
            &:hover {
                color: #fff;
                background-color: $dark-blue;
            }
        }

        &.tab-buy {
            &:hover {
                color: #fff;
                background-color: var(--bs-primary);
            }
        }

        &.tab-sell {
            &:hover {
                color: #fff;
                background-color: var(--bs-orange);
            }
        }

        &.active {
            color: #fff;
            cursor: unset;
            background-color: var(--bs-primary);

            &.tab-buy:hover {
                color: #fff !important;
                background-color: var(--bs-primary) !important;
            }
        }

        &.active-2 {
            color: #fff;
            cursor: unset;
            background-color: var(--bs-orange);

            &.tab-sell:hover {
                color: #fff !important;
                background-color: var(--bs-orange) !important;
            }
        }

        &.active-all {
            color: #fff;
            cursor: unset;
            background-color: $dark-blue;

            &.tab-all:hover {
                color: #fff !important;
                background-color: $dark-blue !important;
            }
        }

        &-2 {
            color: var(--bs-body-color) !important;
            background-color: transparent !important;

            &.active {
                color: #fff !important;
                background-color: var(--bs-primary) !important;
            }

            &.tab-sell:hover,
            &:hover {
                color: #fff;
                background-color: var(--bs-primary) !important;
            }
        }
    }
}

.benefit {
    &-content {
        margin: 1.4rem 0 0;
        max-width: 100%;
    }

    &-item {
        margin-top: 3.2rem;
        width: calc(50% - 3rem);
        padding: 0;
    }

    &-image {
        background: var(--bs-dark);
        @include size(568px, 320px);
        max-width: 100%;
        @include flex(center, center);
        border-radius: $border-radius;
        position: relative;

        &-main {
            @include size(100%);
            object-fit: cover;
            border-radius: $border-radius;
            z-index: 99;
        }


        .ellipse {
            position: absolute;
            z-index: 98;

            &2 {
                left: 0;
                bottom: 0;
            }

            &1 {
                right: 0;
                top: 0;
            }
        }
    }

    &-desc {
        p span {
            color: var(--bs-body-color) !important;
            line-height: 2.8rem !important;
            font-size: 1.6rem !important;
        }
    }
}

#faqs {
    max-width: 79.2rem;
}


.quick-nav {
    &-main {
        box-shadow: 0px 2px 8px rgb(0 0 0 / 5%);
    }

    &-item {
        & + .quick-nav-item {
            margin-left: 2.4rem;
        }
    }

    &-link {
        cursor: pointer;
        padding: 1.3rem 0.7rem;
        line-height: 1.6rem;
        border-bottom: 2px solid transparent;
        color: $c-gray-40;

        span {
            -webkit-transition: all 0.25s ease;
            -moz-transition: all 0.25s ease;

            -o-transition: all 0.25s ease;
            transition: all 0.25s ease;
        }

        svg path {
            -webkit-transition: all 0.25s ease;
            -moz-transition: all 0.25s ease;

            -o-transition: all 0.25s ease;
            transition: all 0.25s ease;
        }

        &.active {
            cursor: unset;
            pointer-events: none;
            user-select: none;
        }

        &:hover,
        &.active {
            border-color: var(--bs-primary);

            span {
                color: var(--bs-primary) !important;
            }

            svg path {
                fill: var(--bs-primary) !important;
            }
        }
    }
}

#adv-detail,
#TheBuyModal,
#TheSellModal {
    .c-dropdown-filter .dropdown-menu {
        left: 0 !important;
    }
}

#quickBuySellBtn {
    position: fixed;
    bottom: 4rem;
    right: 4rem;
    z-index: 999999;
}

.quickBuySellBtn {
    @include size(6rem);
    border-radius: 50%;
    @include flex(center, center);
    background-color: var(--bs-primary);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;

    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    cursor: pointer;
    z-index: 99999;
    position: absolute;
    bottom: 0;
    right: 0;

    &:hover {
        transform: rotate(90deg);
    }

    &.active {
        transform: rotate(135deg) !important;

        &:hover {
            transform: unset;
        }
    }
}

.quick-create-buy,
.quick-create-sell {
    opacity: 0;
    width: 14rem;

    &:hover {
        opacity: 0;
    }

    &.active {
        display: flex;

        &:hover {
            box-shadow: rgba(255, 255, 255, 0.6) 0 0 8px;
        }
    }
}

.quick-create-buy {
    transform: translateY(200%);
    z-index: 997;
    //animation: hideBuytn 0.25s ease-in-out forwards;
}

.quick-create-sell {
    z-index: 998;
    transform: translateY(300%);
    //animation: hideSellBtn 0.15s ease-in-out forwards;
}

.quick-create-buy {
    &.active {
        -webkit-animation: showBuyBtn 0.25s ease-in-out forwards;
        -moz-animation: showBuyBtn 0.25s ease-in-out forwards;
        -o-animation: showBuyBtn 0.25s ease-in-out forwards;
        animation: showBuyBtn 0.25s ease-in-out forwards;
    }
}

.quick-create-sell {
    &.active {
        -webkit-animation: showSellBtn 0.25s ease-in-out forwards;
        -moz-animation: showSellBtn 0.25s ease-in-out forwards;
        -o-animation: showSellBtn 0.25s ease-in-out forwards;
        animation: showSellBtn 0.25s ease-in-out forwards;
    }
}

.border-bottom-dashed {
    border-bottom: 1px dashed #848e9c;
}

@keyframes showSellBtn {
    0% {
        opacity: 0;
        transform: translateY(300%);
    }
    50% {
        opacity: 0;
    }
    100% {
        transform: translateY(-2.6rem);
        opacity: 1;

    }
}

@keyframes showBuyBtn {
    0% {
        opacity: 0;
        transform: translateY(200%);
    }
    50% {
        opacity: 0;
    }
    100% {
        transform: translateY(-13.6rem);
        opacity: 1;

    }
}

@keyframes hideSellBtn {
    0% {
        transform: translateY(-2.6rem);
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        transform: translateY(300%);
        display: none;
    }
}

@keyframes hideBuyBtn {
    0% {
        opacity: 1;
        transform: translateY(-13.6rem);
    }
    50% {
        opacity: 0;
    }
    100% {
        transform: translateY(200%);
        opacity: 0;
        display: none;
    }
}

.adv-detail {
    &-content {
        width: 99.6rem;
        max-width: 100%;
    }
}

@media screen and (min-width: 900px) {
    #merchant-detail,
    #trading-index {
        .table.custom-table td:first-child {
            width: 25%;
            padding-right: 1.6rem;
        }
    }
}
