#login-view {
  background-color: var(--bs-dark);
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  .input-wrapper-lg,.input-group-custom-lg {
    input {
      font-size: 1.4rem !important;
      &::placeholder {
        font-weight: normal !important;
        font-size: 1.4rem !important;
      }
    }
  }

  .pattern {
    position: absolute;

    &-1 {
      left: -280px;
      bottom: -271px;
      @include size(68.5rem);
      border-radius: 50%;
      background: linear-gradient(122.32deg, rgba(53, 93, 255, 0.25) 42.2%, rgba(1, 16, 47, 0) 78.67%);
    }

    &-2 {
      right: -362px;
      top: -514px;
      @include size(80.8rem);
      border-radius: 50%;
      background: linear-gradient(122.32deg, rgba(53, 93, 255, 0.25) 42.2%, rgba(1, 16, 47, 0) 78.67%);
    }
  }

  .header-menu-right:before {
    display: none;
  }

  .c-dropdown {
    .dropdown-toggle {
      padding-right: 0;
      height: $header-height;
    }
  }
}

.login-view {
  &-header {
    height: $header-height;
  }
}

.login {
  &-form {
    &-wrapper {
      width: 58.5rem;
      max-width: 100%;
      @include centerAbsolute('both');
    }
  }
  &-link {
    padding: 1rem 1.6rem;
    border-radius: $border-radius;
    border: 1px solid $border-color;
    //min-height: 5.2rem;
    width: fit-content;
    @include flex(center, flex-start);
  }
  &-tab {
    border-bottom: 2px solid $border-color;
    &-item {
      padding: 1.6rem 1.2rem;
      width: fit-content;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        background-color: var(--bs-primary);
        bottom: -2px;
        left: 0;
      }
    }
  }
}
.show-password-icon {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  cursor: pointer;
}
@media screen and (min-width: 900px){
  .login-form-wrapper {
    margin: 80px auto;
    position: relative;
    transform: translate(0);
    top: unset;
    left: unset;
  }
}
