@media screen and (max-width: $mobile-breakpoint) {
  #navigation-bar {
    padding: 0.6rem 0;
  }
  .navigation-bar-content {
    height: auto;
    display: block;
    font-size: 1.2rem;
    text-align: center;
  }
  #app-available {
    position: fixed;
    left: 0;
    bottom: 0;
    @include size(100%, 7.5rem);
    background-color: #1E2329;
    z-index: 99999999;
    @include flex(center, center);
    padding: 0 6rem;
    opacity: 1;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;

    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;

    span {
      color: #1E3A8A;
    }

    &.isHide {
      opacity: 0;
      transform: translateY(100%);
    }

    .dowload-img {
      width: 50%;
    }

    .download-link {
      display: block;
      height: 4rem;
      border: 1px solid #FFFFFF;
      border-radius: 4px;
      cursor: pointer;

      img {
        @include size(100%);
      }
    }

    .close-download-btn {
      position: absolute;
      right: 1rem;
      top: -2.5rem
    }
  }
}
