.merchant-detail {
  &-info {
    margin: 3.2rem 0;
  }

  &-highlight {
    &-item {
      width: calc(25% - 18px);
      border-radius: $border-radius;
      padding: 2.2rem 2.4rem;
      box-shadow: 0px 8px 64px rgba(43, 50, 59, 0.1);
    }
  }

  &-body {
    padding-top: 5.6rem;
  }
}

.section {
  &-cover {
    height: 32rem;
    border-bottom: 1px solid #eee;

    img {
      @include size(100%);
      object-fit: cover;
    }
  }
}

.profile {
  &-body {
    margin-top: -5.5rem;
    .nav-item {
      .nav-link {
        border-color: $border-color;
        &:hover {
          span {
            color: var(--bs-dark);
          }
          div {
            color: var(--bs-body-color);
            span {
              color: var(--bs-body-color);
            }
          }
        }
        &.active {
          div span {
            color: var(--bs-body-color);
          }
        }
      }
      &:nth-child(2) {
        .nav-link {
          &:hover,
          &.active {
            border-color: var(--bs-orange);
          }
        }
      }
      &:first-child {
        .nav-link {
          &:hover,
          &.active {
            border-color: var(--bs-primary);
          }
        }
      }
    }

    .pagination {
      .page-item {
        &:first-child {
          position: absolute;
          left: 0;
        }
        &:last-child {
          position: absolute;
          right: 0;
        }
      }
    }
  }

  &-intro {
    .user-avatar {
      @include size(22rem);
      border-radius: 50%;
      border: 5px solid #FDFDFD;
      flex-shrink: 0;
      margin-right: 2.4rem;

      img {
        @include size(100%);
        border-radius: inherit;
        object-fit: cover;
      }
    }

    .view-ad-btn {
      height: 4rem;
    }
  }
}

.qr-code {
  @include size(16rem);
}

.activities {
  max-height: 50rem;
  overflow-y: auto;
  padding: 3.2rem;
  min-height: 47.7rem;
  position: relative;
  .nodata,
  .loader-container {
    @include centerAbsolute('both')
  }

  &::-webkit-scrollbar {
    width: 5px !important;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #bbb;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--bs-light);
  }
}

.ratings {
  span {
    display: none !important;
  }

  &.rating-1 {
    span:first-child {
      display: block !important;
    }
  }

  &.rating-2 {
    span:first-child {
      display: inline-block !important;
    }

    span:nth-child(2) {
      display: inline-block !important;
    }
  }

  &.rating-3 {
    span:first-child {
      display: inline-block !important;
    }

    span:nth-child(2) {
      display: inline-block !important;
    }

    span:nth-child(3) {
      display: inline-block !important;
    }
  }

  &.rating-4 {
    span:first-child {
      display: inline-block !important;
    }

    span:nth-child(2) {
      display: inline-block !important;
    }

    span:nth-child(3) {
      display: inline-block !important;
    }

    span:nth-child(4) {
      display: inline-block !important;
    }
  }

  &.rating-5 {
    span {
      display: inline-block !important;
    }
  }
}