@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../icons/gg-icon/MaterialIcons-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('../icons/gg-icon/MaterialIconsOutlined-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Material Icons Rounded';
  font-style: normal;
  font-weight: 400;
  src: url('../icons/gg-icon/MaterialIconsRound-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Material Icons Sharp';
  font-style: normal;
  font-weight: 400;
  src: url('../icons/gg-icon/MaterialIconsSharp-Regular.otf') format('opentype');
}

.material-icons {
  font-family: 'Material Icons';
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
}

.material-icons-round {
  font-family: 'Material Icons Rounded';
}

.material-icons-sharp {
  font-family: 'Material Icons Sharp';
}

.material-icons,
.material-icons-outlined,
.material-icons-round,
.material-icons-sharp {
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: text-bottom;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

/* Rules for sizing the icon. */
.material-icons.md-16,
.material-icons-outlined.md-16,
.material-icons-round.md-16,
.material-icons-sharp.md-16 {
  font-size: 16px;
}

.material-icons.md-24,
.material-icons-outlined.md-24,
.material-icons-round.md-24,
.material-icons-sharp.md-24 {
  font-size: 24px;
}

.material-icons.md-36,
.material-icons-outlined.md-36,
.material-icons-round.md-36,
.material-icons-sharp.md-36 {
  font-size: 36px;
}

.material-icons.md-48,
.material-icons-outlined.md-48,
.material-icons-round.md-48,
.material-icons-sharp.md-48 {
  font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark,
.material-icons-outlined.md-dark,
.material-icons-round.md-dark,
.material-icons-sharp.md-dark {
  color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive,
.material-icons-outlined.md-dark.md-inactive,
.material-icons-round.md-dark.md-inactive,
.material-icons-sharp.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light,
.material-icons-outlined.md-light,
.material-icons-round.md-light,
.material-icons-sharp.md-light {
  color: rgba(255, 255, 255, 1);
}

.material-icons.md-light.md-inactive,
.material-icons-outlined.md-light.md-inactive,
.material-icons-round.md-light.md-inactive,
.material-icons-sharp.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}
  