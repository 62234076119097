@media screen and (max-width: $mobile-breakpoint) {
  #overview {
    padding-bottom: 0 !important;

    #quick-nav {
      margin-bottom: 4rem;

    }

    .quick-nav-link {
      span {
        display: inline-block !important;
        font-size: 1.2rem;

        &.ionicon {
          font-size: 2rem;
        }
      }

      &:hover,
      &.active {
        color: var(--bs-dark) !important;

        span {
          color: var(--bs-dark) !important;

          &.ionicon {
            color: var(--bs-primary) !important;
          }
        }
      }
    }

    .section-heading {
      font-size: 2.4rem !important;
    }

    .current-method-list {
      margin-top: 2.4rem;
    }
  }
  .card {
    &-overview {
      width: 100%;
      padding: $padding-mobile;
      min-height: 26.8rem;

      & + .card-overview {
        margin-top: 2.4rem;
      }
    }
  }
  .current-method-option {
    padding: 1.65rem 2rem;
    &:first-child {
      margin-top: 0;
    }

    margin-top: $padding-mobile;
  }
  .payment-method-option {
    padding: 1.7rem 2rem;
  }
  .blacklist {
    &-item {
      border-bottom: 1px solid $border-color;
      padding-bottom: $padding-mobile;
      &:not(:last-child) {
        margin-bottom: $padding-mobile;
      }
    }

    &-card {
      padding: 0;
      border-radius: 0;
      border: 0;
    }

    &-filter {
      height: 0;
      overflow: hidden;
      opacity: 0;
      -webkit-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;

      -o-transition: all 0.25s ease;
      transition: all 0.25s ease;
      transform: translateY(-5%);
      &.isShow {
        height: auto;
        overflow: auto;
        opacity: 1;
        transform: translateY(0);
        margin-top: 2.4rem;
      }
      .filter-item {
        margin: 0 !important;
        width: 100%;

        & + .filter-item {
          margin-top: 1.2rem !important;
        }
      }
    }
  }
  .filter-icon {
    &.isShow {
      svg path {
        fill: var(--bs-dark);
      }
    }
  }
  .custom-datepicker {
    .dp__input {
      width: calc(100vw - 34px);
    }
  }
}
@media (min-width: 900px) and (max-width: $desktop-breakpoint){
  .card-overview {
    width: 100%;
    & + .card-overview {
      margin-top: 2.4rem;
    }
  }
  #overview {
    th, td {
      max-width: 20rem !important;
    }
  }
}
@media (min-width: $tablet-breakpoint) and (max-width: $desktop-breakpoint){
  .card-overview {
    width: calc(50% - 12px);
    & + .card-overview {
      margin-top: 0;
    }
  }
}
@media (min-width: 900px) and (max-width: $tablet-breakpoint) {
  .card-overview {
    min-height: 25.2rem;
    &-item {
      width: 50%;
    }
  }
}
