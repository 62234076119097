@media screen and (max-width: $mobile-breakpoint) {
  #order {
    &-detail {
      margin-top: 2.4rem;
      position: relative;

      .card {
        padding: $padding-mobile;
      }

      .quick-chat-btn {
        z-index: 99;
        bottom: 1.6rem;
        right: 1.6rem;
      }
    }
    .tab-list {
      margin-top: 0 !important;
    }
  }
  #myChat {
    .my-chat {
      position: fixed !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
      z-index: 999999;
      transform: translateX(100%);
      opacity: 0;
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;

      -o-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;

      &.isShowMobile {
        transform: translateX(0);
        opacity: 1;
      }

      &-footer,
      &-header {
        padding: 2rem $padding-mobile;
      }

      &-body {
        max-height: calc(100vh - 80px - 93px);
        padding: 2rem $padding-mobile;
      }
    }
  }
  .order {
    &-detail {
      &-body {
        margin-right: 0;
      }

      &-type {
        font-size: 1.6rem !important;
      }
    }

    &-warning-box {
      padding: $padding-mobile !important;
    }
  }
  .detail-order {
    &-left,
    &-right {
      width: auto;
      max-width: 100%;
    }
  }
  .crypto-image {
    width: 100%;

    img {
      @include size(100%);
    }
  }
  .timer-text {
    position: absolute;
    top: 1px;
    right: 1.4rem;
    padding-left: 1rem;
    border-left: 1px solid $border-color;
    margin-left: 1rem;
  }
  .detail-order {
    &-left,
    &-right {
      width: 100%;
    }
  }
  .crypto-time {
    .timer-text {
      position: relative;
      border-left: 0;
    }
  }
}

@media (min-width: 900px) and (max-width: $desktop-breakpoint) {
  #order {
    .card-col-md, .card-col-xl {
      align-items: center;
    }
  }
  .order-by-adv-detail-col {
    &:first-child {
      padding-right: 2.4rem;
    }

    &:nth-child(2) {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
    }

    &:last-child {
      padding-left: 2.4rem;
    }
  }
  #TheListOrderByAdv {
    .card-col-md-xl {
      width: 20%;
    }
  }
  .detail-order {
    &-left,
    &-right {
      width: 100%;
    }

    &-left {
      border-top: 0 !important;
      margin-top: 3.2rem;

      .crypto-image {
        @include size(8.5rem, 5.6rem);

        img {
          @include size(100%);
        }
      }
    }
  }
}