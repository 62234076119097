.order-status {
    position: relative;
    padding: 0.4rem 1.2rem;
    color: var(--bs-dark);
    border-radius: 99rem;
    text-align: center;
    width: fit-content;
    max-height: 2.4rem;

    &-icon {
        position: relative;
        padding-left: 1.6rem;

        &::before {
            position: absolute;
            content: '';
            @include size(8px);
            border-radius: 50%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &-offline {
            color: $c-gray-40;

            &::before {
                background-color: $c-gray-40;
            }
        }

        &-success {
            color: var(--bs-dark);

            &::before {
                background-color: $c-success-2;
            }
        }

        &-close {
            color: var(--bs-dark);

            &::before {
                background-color: #EF5B44;
            }
        }
    }

    &-outline {
        padding-left: 1.4rem;

        &::before {
            position: absolute;
            content: '';
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            @include size(8px);
            border-radius: 50%;
        }
    }

    &-text {
        padding-left: 0;

        &-offline,
        &-1 {
            color: $c-gray-40;
        }

        &-success,
        &-2,
        &-4 {
            color: $c-toast-success-icon;
        }

        &-CANCELLED {
            color: #F88371;;
        }

        &-APPEALING {
            color: $c-toast-warning-icon;
        }

        &::before {
            display: none;
        }
    }

    &-outline-success {
        &::before {
            background-color: $c-toast-success-icon;
        }
    }

    &-outline-warning {
        &::before {
            background-color: $c-toast-warning-icon;
        }
    }

    &-outline-offline {
        &::before {
            background-color: $border-color;
        }
    }

    &-outline-danger {
        &::before {
            background-color: $c-toast-error-icon;
        }
    }

    &-2,
    &-4,
    &-success {
        background-color: $c-bg-success;
        color: $c-success-dark;
    }

    &-APPEALING,
    &-warning {
        background-color: $c-toast-warning;
        color: var(--bs-primary);
    }

    &-offline {
        background-color: #F4F5F7;
        color: var(--bs-dark);
    }

    &-CANCELLED,
    &-danger {
        background-color: $c-toast-error-bg;
        color: $c-danger;
    }

    &-1 {
        background-color: var(--bs-light);
        color: var(--bs-body-color);
    }
}

.quick-chat-btn {
    position: fixed;
    bottom: 4rem;
    right: 4rem;
    @include size(6rem);
    border-radius: 50%;
    @include flex(center, center);
    background-color: var(--bs-primary);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    cursor: pointer;
    z-index: 99999;
}

#myChat {
    .fbot-bottom-list {
        display: none;
    }

    .my-chat {
        width: 38.4rem;
        height: 66.8rem;
        flex-shrink: 0;
        max-width: 100%;
        border-radius: 1.2rem;
        border: 1px solid $border-color;
        background-color: $body-background;

        &-fixed {
            position: fixed !important;
            right: 0;
            top: 50%;
            transform: translate(100%, -50%);
            opacity: 0;
            -webkit-transition: all 0.25s ease-in-out;
            -moz-transition: all 0.25s ease-in-out;

            -o-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            z-index: 99999;

            &.isShow {
                opacity: 1;
                transform: translate(0, -50%);
            }

            .my-chat-label {
                display: flex !important;
                flex-shrink: 0;
                padding: 1rem 2rem;
                border-bottom: 1px solid $border-color;
                background-color: var(--bs-light);
                border-top-left-radius: 1.2rem;
            }
        }

        &-label {
            display: none !important;
        }

        &-header {
            border-bottom: 1px solid $border-color;
        }

        &-header,
        &-footer {
            flex-shrink: 0;
            padding: 2.2rem 2rem;
        }

        &-footer {
            height: 8rem;
            margin-top: auto;
            border-top: 1px solid $border-color;

            input {
                flex: 1;
                margin-right: 2rem;
                border: 0;
                height: 100%;
                outline: 0;
                color: var(--bs-dark);

                &::placeholder {
                    color: #B6BEC9;
                }
            }
        }

        &-body {
            flex: 1;
            padding: 2rem;

            max-height: 50.1rem;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 5px !important;
                position: absolute;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background: #bbb;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: var(--bs-light);
            }
        }

        &-item {
            & + .my-chat-item {
                margin-top: 2rem;
            }
        }

        &-content {
            max-width: 26.3rem;
            padding: 1.2rem;
            border-radius: $border-radius;
            font-size: 1.2rem;
            text-align: left;
            line-height: 1.6rem;
            width: fit-content;

            &.is-typing {
                padding: 1.7rem 2.5rem;
                width: fit-content;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &-is-me {
            text-align: right;

            .my-chat-content {
                color: #fff;
                margin-left: auto;
                background-color: var(--bs-primary);

                &-image {
                    padding: 0;
                    background-color: transparent;
                }
            }
        }

        &-is-you {
            .my-chat-content {
                color: var(--bs-dark);
                background-color: #F4F5F7;

                &-image {
                    padding: 0;
                    background-color: transparent;
                }
            }
        }

        &-time {
            display: inline-block;
            margin-top: 0.8rem;
        }

        &-avatar {
            @include size(2.4rem);
            border-radius: 50%;
            @include flex(center, center);
            font-size: 1rem;
            color: #fff;
            margin-right: 0.8rem;

            img {
                @include size(100%);
                object-fit: cover;
                border-radius: 50%;
            }
        }
    }
}

.order {
    &-summary {
        &-title {
            width: 100px;
            flex-shrink: 0;
        }
    }

    &-detail-body {
        flex: 1;
        margin-right: 2.4rem;
    }

    &-rating {
        .c-form-group {
            textarea {
                border-radius: 0.8rem;

                &::placeholder {
                    color: #B6BEC9;
                }
            }
        }
    }
}

.order-warning-box {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    padding: 2rem;
    width: 100%;
    display: block;
}

.order-review {
    .nk-reply-body {
        margin-left: 4.75rem;
    }
}

.handle-step {
    .loader {
        display: none;
    }

    &.notActive {
        .loader {
            display: block;
        }

        .checked-icon {
            display: none;
        }

        span {
            color: var(--bs-body-color) !important;
        }
    }
}

.detail-order {
    &-left {
        width: 48.6rem;
    }

    &-right {
        width: 58.8rem;
    }
}

#order {
    .card-row {
        max-height: unset;
    }
}
