@media screen and (max-width: $mobile-breakpoint) {
    .custom-modal {
        z-index: 99999999;
        padding-bottom: 0;

        .modal {
            &-content {
                padding: 2rem $padding-mobile;
                max-width: calc(100vw - 32px);
                margin: auto 0 0 0;

                .row {
                    margin: 0;
                }

                &-line {
                    &::after {
                        display: none;
                    }
                }
            }

            &-col-left {
                padding: 0;
            }

            &-col-right {
                padding: 0;
            }
        }
    }
    #TheBuyModal,
    #TheSellModal {
        .custom-modal {
            .modal-content {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }
    #VerifyKycModal,
    #CreateMerchantNameModal,
    #TheAddBlackListModal,
    #EditPaymentMethodModal,
    #TheRefreshModal,
    #ChoosePaymentMethodModal,
    #TheWithdrawCoinModal,
    #TheConfirmModal,
    #TheConfirmTransactionModal,
    #TheCancelOrderModal,
    #TheAppealOrderModal,
    #TheQuickBuySellModal,
    #TheSellModal,
    #TheBuyModal {
        #NotificationModal {
            .custom-modal {
                .modal-content {
                    height: auto;
                    padding-bottom: 12rem;
                }
            }
        }

        .custom-modal {
            .modal-content {
                max-width: 100%;
                width: 100%;
                height: 100vh;
                overflow-y: auto;
                -webkit-animation: showModal 0.25s ease-in-out forwards;
                -moz-animation: showModal 0.25s ease-in-out forwards;
                -o-animation: showModal 0.25s ease-in-out forwards;
                animation: showModal 0.25s ease-in-out forwards;
                transform: translate(0);
                left: 0;
                padding-bottom: 12rem;
                border-radius: 0;
            }

            &.isShow {
                .notification-modal {
                    border-radius: 0;
                }
            }
        }

        .filter-header {
            display: flex !important;
            position: relative;

            .filter-title {
                @include centerAbsolute('both')
            }
        }

        .loading-overlay {
            display: none;
            height: 100%;

            &-mobile {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.25);
                z-index: 999;

                .loading-block {
                    height: 100%;
                }
            }
        }
    }
    #ChoosePaymentMethodModal,
    #TheWithdrawCoinModal {
        .custom-modal {
            .modal-content {
                border-top-left-radius: 1.2rem;
                border-top-right-radius: 1.2rem;
                bottom: 0;
                height: auto;
                //max-height: 100vh;
            }
        }
    }
    #TheConfirmTransactionModal {
        .custom-modal {
            .modal-content {
                max-height: unset;
                height: auto;
                border-top-left-radius: 1.2rem;
                border-top-right-radius: 1.2rem;
                bottom: 0;
                overflow-y: unset;
            }
        }
    }


    #TheConfirmTransactionModal {
        .custom-modal {
            .modal-content {
                max-height: 42rem;
            }
        }
    }
    #ChoosePaymentMethodModal.payment-method-sell {
        .custom-modal {
            background-color: $body-background;
        }

        .modal-content {
            min-height: 100vh;
        }

        .modal-wrapper {
            min-height: unset;
        }
    }
    #ChoosePaymentMethodModal.payment-method-sell,
    #TheWithdrawCoinModal,
    #TheCancelOrderModal,
    #TheAppealOrderModal,
    #TheQuickBuySellModal {
        .custom-modal {
            .modal {
                &-content {
                    overflow-y: unset;
                }

                &-wrapper {
                    padding: 2.4rem $padding-mobile 10rem;
                    border-radius: 0;
                }
            }
        }

        .modal-title {
            font-size: 2rem !important;
        }
    }
    #TheCancelOrderModal,
    #TheAppealOrderModal {
        .custom-modal {
            .modal-title {
                font-size: 1.8rem !important;
            }
        }
    }

    #TheConfirmModal {
        .custom-modal {
            .modal-title {
                font-size: 2rem !important;
            }

            .modal-content {
                padding-bottom: 15rem !important;
            }
        }

        .confirm {
            &-body {
                padding: 2.4rem 0 0.8rem;
                margin: 1.4rem 0;
                border-top: 1px solid $border-color;
            }

            &-row {
                flex-wrap: wrap;

                & + .confirm-row {
                    margin-top: 0;
                }
            }

            &-item-haft,
            &-item {
                width: 100%;
                @include flex(center, space-between);
                margin-bottom: $padding-mobile;
            }
        }
    }
    #ChoosePaymentMethodModal,
    #TheWithdrawCoinModal {
        .custom-modal {
            .modal-title {
                font-size: 2rem;
            }
        }
    }
    #VerifyKycModal,
    #CreateMerchantNameModal,
    #TheRefreshModal {
        .custom-modal {
            .modal-content {
                height: auto;
                bottom: 0;
                border-radius: 12px 12px 0 0;
            }

            .modal-title {
                font-size: 1.8rem;
            }
        }
    }
    #VerifyKycModal,
    #CreateMerchantNameModal {
        .custom-modal {
            .modal-content {
                padding: $padding-mobile $padding-mobile 10rem;
            }
        }
    }
    #TheAddBlackListModal,
    #EditPaymentMethodModal, {
        .custom-modal.hideNotify {
            .modal-content {
                border-radius: 1.2rem 1.2rem 0 0;
                height: calc(100vh - 64px);
            }
        }

        .custom-modal {
            &.isShow {
                display: block;
            }

            .modal-content {
                border-radius: 1.2rem 1.2rem 0 0;
                min-height: calc(100vh - 6.4rem);
                height: unset;

                &.isHigh {
                    min-height: calc(100vh - 11.2rem);
                }
            }
        }

        .c-dropdown-select .dropdown-toggle {
            height: 4.8rem;
        }

        .modal-action {
            margin-top: auto !important;
        }
    }
    //#EditPaymentMethodModal {
    //  .custom-modal {
    //    .modal-content {
    //      padding-bottom: 20rem;
    //    }
    //  }
    //}
    #TheAddBlackListModal {
        .c-form-group {
            .input-wrapper {
                input {
                    padding: 1.2rem 1.6rem;
                }
            }

            textarea {
                font-size: 1.4rem;
                padding: 1.2rem 1.6rem;

                &::placeholder {
                    font-size: 1.4rem;
                }
            }
        }
    }
    #The2FAModal {
        .custom-modal {
            .modal-content {
                height: auto;
                min-height: 50vh;
                top: unset;
                position: fixed;
                bottom: 0;
                left: 0;
                justify-content: flex-start;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
            }

            .modal-action {
                margin-top: 3.2rem !important;
            }
        }
    }
    #login-view {
        #The2FAModal {
            .custom-modal {
                .modal-content {
                    border-radius: 0 !important;
                    top: 6.4rem;
                }
            }
        }
    }
    @keyframes showModal {
        from {
            opacity: 0;
            transform: translateY(100%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

@media screen and (max-width: 769px) {
    #NotificationModal {
        .custom-modal {
            .modal-content {
                padding: 2.4rem $padding-mobile 10rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                max-width: 100%;
                width: 100%;
                -webkit-animation: showModal 0.25s ease-in-out forwards;
                -moz-animation: showModal 0.25s ease-in-out forwards;
                -o-animation: showModal 0.25s ease-in-out forwards;
                animation: showModal 0.25s ease-in-out forwards;
                transform: translate(0);
                left: 0;
                bottom: 0;
                border-radius: 12px 12px 0px 0px;

                h2 {
                    font-size: 2rem;
                }

                .notify-desc {
                    font-size: 1.4rem !important;
                }

                .notify-icon {
                    @include size(6.4rem);
                    margin: 0 auto;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .filter-header {
            display: flex !important;
            position: relative;

            .filter-title {
                @include centerAbsolute('both')
            }
        }

        .loading-overlay {
            display: none;
            height: 100%;

            &-mobile {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.25);
                z-index: 999;

                .loading-block {
                    height: 100%;
                }
            }
        }
    }
}

@media (min-width: 769px) and (max-width: $mobile-breakpoint) {
    #NotificationModal {
        .custom-modal {
            .modal-content {
                -webkit-animation: showModal2 0.25s ease-in-out forwards;
                -moz-animation: showModal2 0.25s ease-in-out forwards;
                -o-animation: showModal2 0.25s ease-in-out forwards;
                animation: showModal2 0.25s ease-in-out forwards;
            }
        }
    }
    @keyframes showModal2 {
        from {
            opacity: 0;
            top: 45%;
        }
        to {
            opacity: 1;
            top: 50%;
        }
    }
}
