.custom-alert {
  border: 1px solid transparent;
  border-radius: $border-radius;
  &-sm {
    .alert-content {
      min-height: 4rem;
    }
  }
  .alert-content {
    min-height: 5.6rem;
    padding: 1.6rem;
    @include flex(center, flex-start);
  }
  .alert-icon {
    @include size(2.4rem);
    border-radius: 50%;
    @include flex(center, center);
    margin-right: 1.2rem;
    flex-shrink: 0;
  }
  .alert-message {
    font-weight: 600;
    font-size: 1.2rem;
  }
  &-warning {
    border-color: $c-toast-warning;
    background-color: $c-toast-warning-bg;
    .alert-icon {
      background-color: $c-toast-warning-icon;
    }
    .alert-message {
      color: $c-toast-warning-text;
    }
  }
  &-success {
    border-color: $c-toast-success;
    background-color: $c-toast-success-bg;
    .alert-icon {
      background-color: $c-toast-success-icon;
    }
    .alert-message {
      color: $c-toast-success-text;
    }
  }
  &-error {
    border-color: $c-toast-error;
    background-color: $c-toast-error-bg;
    .alert-icon {
      background-color: $c-toast-error-icon;
    }
    .alert-message {
      color: $c-toast-error-text;
    }
  }
  &-info {
    border-color: $c-toast-info;
    background-color: $c-toast-info-bg;
    .alert-icon {
      background-color: $c-toast-info-icon;
    }
    .alert-message {
      color: $c-toast-info-text;
    }
  }
}