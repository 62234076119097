.text-smallest {
  font-size: 1rem;
}

.text-small {
  font-size: 1.2rem;
}

.text-large {
  font-size: 1.6rem;
}

.fs-10px {
  font-size: 1rem;
}

.fs-14px {
  font-size: 1.4rem;
}

.fs-18px {
  font-size: 1.8rem;
}

.fs-20px {
  font-size: 2rem;
}

.fs-22px {
  font-size: 2.2rem;
}

.fs-24px {
  font-size: 2.4rem;
}

.fs-28px {
  font-size: 2.8rem;
}

.fs-32px {
  font-size: 3.2rem;
}

.fs-40px {
  font-size: 4rem;
}

.fs-55px {
  font-size: 5.5rem;
}

.fs-60px {
  font-size: 6rem;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allow {
  cursor: unset;
  pointer-events: none;
  user-select: none;
}

.cursor-not-allow {
  cursor: unset;
  user-select: none;
  pointer-events: none;
}

.c-btn {
  border: 0;
  width: fit-content;
  border-radius: $btn-radius;
  padding: 0 1.6rem;
  font-size: 1.4rem;
  min-width: $button-min-width;
  height: $button-height;
  display: block;
  font-weight: 600;
  line-height: 1.6rem;
  cursor: pointer;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;

  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  @include flex(center, center);

  &:hover {
    opacity: 0.8;
  }

  &-xs {
    height: 3.6rem;
    padding: 0 1.8rem;
  }
  &-sm {
    height: 4rem;
    padding: 0 2.2rem;
  }

  &-md {
    height: 4.8rem;
  }

  &-primary {
    background-color: var(--bs-primary);
    color: #fff;

    &:hover {
      color: #fff !important;
    }
  }
  &-success {
    background-color: var(--bs-success);
    color: #fff;

    &:hover {
      color: #fff !important;
    }
  }
  &-danger {
    background-color: var(--bs-danger);
    color: #fff;

    &:hover {
      color: #fff !important;
    }
  }

  &-outline-secondary {
    border: 1px solid $border-color;
  }

  &-orange {
    background-color: var(--bs-orange);
    color: #fff;
  }

  &-secondary {
    color: var(--bs-body-color);
    background-color: #F4F5F7;
  }

  &-gray {
    background-color: $c-gray;
    color: #fff;

    &:hover {
      color: #fff;
    }

    & > * {
      color: #fff;
    }
  }

  &-outline-primary {
    color: var(--bs-primary);
    border: 1px solid rgba(53, 93, 255, 0.15);
  }

  &-outline-orange {
    color: var(--bs-orange);
    border: 1px solid rgba(251, 129, 52, 0.15);
  }

  &-outline-dark {
    color: var(--bs-dark);
    border: 1px solid $border-color;
  }

  &-outline-danger {
    color: var(--bs-danger);
    border: 1px solid #FEE6E2;
    &:hover {
      border-color: var(--bs-danger);
      opacity: 1;
      color: #FFFFFF;
      background-color: var(--bs-danger);
    }
  }
}

.c-btn-2 {
  width: fit-content;
  border-radius: $border-radius;
  padding: 0 3.2rem;
  height: $button-height;
  display: block;
  font-weight: 600;
  line-height: 1.6rem;
  cursor: pointer;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;

  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  @include flex(center, center);

  &-sm {
    height: 2.4rem !important;
    border-radius: 0.4rem;
  }

  img, span {
    margin-right: 0.5rem;
  }

  &:hover {
    opacity: 0.8;
  }

  &-primary {
    background-color: var(--bs-primary);
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  &-outline-secondary {
    border: 1px solid $border-color;

    &:hover {
      color: var(--bs-dark);
      opacity: 1;
      border-color: var(--bs-dark);
    }
  }

  &-outline-primary {
    color: var(--bs-primary);
    border: 1px solid var(--bs-primary);

    &:hover {
      color: #fff;
      opacity: 1;
      background-color: var(--bs-primary);
    }
  }

  &-orange {
    background-color: var(--bs-orange);
    color: #fff;
  }

  &-gray {
    background-color: $c-gray;
    color: #fff;

    &:hover {
      color: #fff;
    }

    & > * {
      color: #fff;
    }
  }

  &-outline-blue {
    border: 1px solid rgba(53, 93, 255, 0.15);;
  }
}

.btn-icon {
  @include size(78px);
  @include flex(center, center);
  padding: 0;
  border-radius: 50%;
  background-color: $c-gray;

  & > * {
    font-size: 16px;
  }

  &-sm {
    @include size(40px);
    @include flex(center, center);
    padding: 0;
    border-radius: 50%;

    &-success {
      background-color: $c-bg-success;

      & > * {
        font-size: 2rem;
        color: $c-success;
      }
    }
  }

  &-circle {
    @include size(4rem);
    border-radius: $border-radius;
    border: 1px solid $border-color;
    @include flex(center, center);
  }
}

.btn-filter {
  border: 1px solid $border-color;
  border-radius: 8px;
  &.isShow {
    border-color: var(--bs-dark);
    svg path {
      fill: var(--bs-dark) !important;
    }
  }
}

.btn-status {
  height: 3.2rem;
  @include flex(center, center);
  border: 1px solid transparent;
  border-radius: 0.8rem;
  font-weight: 600;
  padding: 0 1.5rem 0 3rem;
  position: relative;

  &::after {
    position: absolute;
    content: '';
    border-radius: 50%;
    @include size(0.8rem, 0.8rem);
    top: 50%;
    transform: translateY(-50%);
    left: 1.5rem;
  }

  &-hide {
    border-color: $border-color;
    color: $gray;

    &::after {
      background-color: $gray;
    }
  }

  &-show {
    border-color: #A7F3D0;
    color: $success;

    &::after {
      background-color: $success;
    }
  }
}

.image-circle {
  border-radius: 50%;
  @include size(4rem);
  flex-shrink: 0;
  background-color: var(--bs-light);

  img {
    @include size(100%);
    border-radius: 50%;
    object-fit: cover;
  }

  &-lg {
    @include size(6.4rem);
    flex-shrink: 0;


    img {
      @include size(100%);
      border-radius: 50%;
    }
  }

  &-md {
    @include size(3.2rem);
    flex-shrink: 0;

    img {
      @include size(100%);
      border-radius: 50%;
    }
    &-no-pill {
      @include size(3.2rem);
      img {
        flex-shrink: 0;
        @include size(100%);
        object-fit: contain;
      }
    }

  }

  &-sm {
    @include size(2rem);
    flex-shrink: 0;

    img {
      @include size(100%);
      border-radius: 50%;
    }
  }

  &-xl {
    @include size(4.8rem);
    flex-shrink: 0;

    img {
      @include size(100%);
      border-radius: 50%;
    }
  }

  &-coins {
    @include size(2rem);
    flex-shrink: 0;
    img {
      @include size(100%);
      border-radius: 50%;
    }
    &-no-pill {
      @include size(2rem);
      flex-shrink: 0;
      img {
        @include size(100%);
        object-fit: contain;
        border-radius: 0;
      }
    }
    &-sm {
      @include size(1.6rem);
      flex-shrink: 0;
      img {
        @include size(100%);
        border-radius: 50%;
      }
    }
    &-lg {
      @include size(4rem);
      flex-shrink: 0;
      img {
        @include size(100%);
        border-radius: 50%;
      }
    }
  }
}

.search-input-sm {
  input {
    border: 0;
    border-radius: $border-radius;
    @include size(100%);
    outline: 0;
    padding: 0 0.5rem;
  }
}

.section-title {
  letter-spacing: 3px;
  font-size: 1.2rem;
}

.line-1 {
  display: -webkit-box !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
}

.line-2 {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
}

.line-3 {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
}

.line-5 {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
}

.input-wrapper {
  @include flex(center, space-between, row-reverse);
  border: 1px solid $border-color;
  height: 4.6rem;
  border-radius: $border-radius;

  input {
    height: 100%;
    width: 70%;
    border: 0;
    outline: 0;
    border-radius: $border-radius;
    padding-left: 1.6rem;
    &::placeholder {
      font-weight: 500;
    }
  }

  label {
    padding-right: 2rem;
  }

  &-lg {
    height: 6rem;

    input {
      font-size: 1.6rem;

      &::placeholder {
        font-size: 1.6rem;
      }
    }
  }

  &-sm {
    height: 4.8rem;
  }

  &-md {
    input {
      font-size: 1.2rem;
      padding-right: 2rem;

      &::placeholder {
        font-size: 1.2rem;
      }
    }
  }

  &-xxl {
    height: 6.4rem;

    .c-dropdown {
      .dropdown-toggle {
        width: 100% !important;
        justify-content: space-between !important;
        height: 6.4rem !important;
        padding: 0 2rem !important;

        .btn-content {
          span {
            color: var(--bs-dark) !important;
          }
        }

        &::after {
          border-width: 0.6rem !important;
        }
      }
    }
  }
}
.input-group-custom {
  //@include flex(center, space-between);
  border: 1px solid $border-color;
  height: 4.6rem;
  border-radius: $border-radius;

  input {
    height: 100%;
    width: 70%;
    border: 0;
    outline: 0;
    border-radius: $border-radius;
    padding-left: 1.6rem;
    &::placeholder {
      font-weight: 500;
    }
  }

  label {
    padding-right: 2rem;
  }

  &-lg {
    height: 6rem !important;

    input {
      font-size: 1.6rem;

      &::placeholder {
        font-size: 1.6rem;
      }
    }
  }

  &-sm {
    height: 4.8rem;
  }

  &-md {
    input {
      font-size: 1.2rem;
      padding-right: 2rem;

      &::placeholder {
        font-size: 1.2rem;
      }
    }
  }

  &-xxl {
    height: 6.4rem;

    .c-dropdown {
      .dropdown-toggle {
        width: 100% !important;
        justify-content: space-between !important;
        height: 6.4rem !important;
        padding: 0 2rem !important;

        .btn-content {
          span {
            color: var(--bs-dark) !important;
          }
        }

        &::after {
          border-width: 0.6rem !important;
        }
      }
    }
  }
}

.highlight {
  position: absolute;
  top: 50%;
  left: 1.2rem;
  transform: translateY(-50%);
  content: '';
  width: 8px;
  height: 16px;
  border-radius: 2px;

  &-2 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    content: '';
    width: 4px;
    height: 12px;
    border-radius: 1px;
  }
  &-3 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    content: '';
    width: 8px;
    height: 16px;
    border-radius: 2px;
  }

  &-primary {
    background-color: var(--bs-primary);
  }

  &-circle {
    position: absolute;
    top: 50%;
    left: 1.2rem;
    transform: translateY(-50%);
    content: '';
    @include size(8px);
    border-radius: 50%;
    background-color: $gray;
  }
}

/*Margin*/
.ms-12 {
  margin-left: 1.2rem;
}

.me-12 {
  margin-right: 1.2rem;
}

.n-mt-40 {
  margin-top: -4rem;
}

.mb-12 {
  margin-bottom: 1.2rem;
}
.mt-12 {
  margin-top: 1.2rem;
}

.mb-16 {
  margin-bottom: 1.6rem;
}

.mt-16 {
  margin-top: 1.6rem;
}

.mt-2px {
  margin-top: 0.2rem !important;
}

.mt-4px {
  margin-top: 0.4rem !important;
}

.mt-8 {
  margin-top: 0.8rem !important;
}

.mb-8 {
  margin-bottom: 0.8rem;
}

.ms-8 {
  margin-left: 0.8rem;
}

.me-8 {
  margin-right: 0.8rem;
}

.mt-24 {
  margin-top: 2.4rem;
}

.mb-24 {
  margin-bottom: 2.4rem;
}

.mx-24 {
  margin-left: 2.4rem !important;
  margin-right: 2.4rem;
}

.mb-32 {
  margin-bottom: 3.2rem;
}

.mb-40 {
  margin-bottom: 4rem;
}

.mb-48 {
  margin-bottom: 4.8rem;
}

.mt-48 {
  margin-top: 4.8rem;
}

.mt-50 {
  margin-top: 5rem;
}

.mt-60 {
  margin-top: 6rem;
}

.mt-80 {
  margin-top: 8rem;
}

.mt-32 {
  margin-top: 3.2rem;
}

.mb-70 {
  margin-bottom: 7rem;
}

.mb-80 {
  margin-bottom: 8rem;
}

/*Padding*/
.p-24 {
  padding: 2.4rem !important;
}

.pb-08 {
  padding-bottom: 0.8rem;
}

.pt-80 {
  padding-top: 8rem;
}

.pt-24 {
  padding-top: 2.4rem;
}

.pb-24 {
  padding-bottom: 2.4rem;
}

.pb-16 {
  padding-bottom: 1.6rem;
}

.pt-16 {
  padding-top: 1.6rem;
}

.pb-20 {
  padding-bottom: 2rem !important;
}

.ps-12 {
  padding-left: 1.2rem;
}

.pe-12 {
  padding-right: 1.2rem;
}

.pe-16 {
  padding-right: 1.6rem;
}

.pb-32 {
  padding-bottom: 3.2rem;
}

.pt-48 {
  padding-top: 4.8rem;
}

.pb-48 {
  padding-bottom: 4.8rem;
}

.pb-70 {
  padding-bottom: 7rem;
}

.pb-80 {
  padding-bottom: 8rem;
}

.lh-16 {
  line-height: 1.6rem;
}

.lh-24 {
  line-height: 2.4rem;
}

.lh-28 {
  line-height: 2.8rem;
}

.letter-space-3 {
  letter-spacing: 3px
}

.no-data {
  min-height: 33.8rem;
  @include flex(center, center, column);
  width: $desktop-container-sm;
  max-width: 100%;
  margin: 3rem auto 0;
  font-weight: 600;
  font-size: 1.6rem;
}

.wh-14 {
  width: 1.4rem;
  height: 1.4rem;
}

.dot {
  @include size(6px);
  border-radius: 50%;
  background-color: var(--bs-body-color);
  display: inline-block;

  &-sm {
    @include size(4px);
    border-radius: 50%;
    background-color: $c-gray-40;
    display: inline-block;
  }
}

.disabled {
  user-select: none;
  pointer-events: none;
  opacity: 0.8;
}

.copy-icon {
  color: var(--bs-gray);
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.min-792 {
  min-height: 79.2rem;
}

.w-18 {
  width: 18% !important;
}
.w-56 {
  width: 56% !important;
}
.w-40 {
  width: 40% !important;
}
.w-54 {
  width: 54% !important;
}

.w-fit-content {
  width: fit-content;
  min-width: unset;
}

.wrap {
  white-space: break-spaces;
}

.nowrap {
  white-space: nowrap;
}

.min-h-100 {
  min-height: 10rem;
}
.min-h-210 {
  min-height: 21rem;
}

.min-h-330 {
  min-height: 33rem;
}

.min-h-420 {
  min-height: 42rem !important;
}

.min-h-500 {
  min-height: 50rem !important;
}

.rounded-12 {
  border-radius: $border-radius;
}

.word-break {
  word-break: break-all;
}

.c-alert {
  padding: 2rem;
  border-radius: $border-radius;

  &-gray {
    background-color: $light;
  }
}

.h-48 {
  height: 4.8rem;
}

.hover-link {
  svg path {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  &:hover {
    & > * {
      color: var(--bs-primary) !important;
    }
    svg path {
      fill: var(--bs-primary) !important;
    }
  }
}

.loading-icon {
  transform: rotate(0);

  &.isLoading {
    animation: rotate 0.45s linear infinite;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.isCopy {
  path {
    fill: var(--bs-success)
  }
}
.letter-spacing {
  letter-spacing: 3px;
}
html, body, #app {
  min-height: 100%;
  width: 100%;
}

svg {
  &:focus {
    outline: 0;
  }
}
.fs-10px {
  font-size: 10px;
}
.coin-currency {
  border: 1px solid #E6E9EC;
  border-radius: 0.8rem;
  padding: 10px;
}
.radius-12 {
  border-radius: 12px;
}
.c-badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 12px;
  width: fit-content;
  border-radius: 16px;
  font-weight: 500;
  font-size: 12px;

  &-danger {
    color: $c-danger;
    background: $c-bg-danger;
  }

  &-success {
    color: $c-success;
    background: $c-bg-success;
  }
}
.btn-circle {
  border-radius: 50%;
  @include size(40px);
  @include flex(center, center);

  &-lg {
    @include size(60px);
  }

  svg {
    width: 20px;
    height: 20px;
  }

  &-danger {
    background: $c-bg-danger;

    svg path {
      fill: $c-danger !important;
    }
  }

  &-success {
    background: $c-bg-success;

    svg path {
      fill: $c-success !important;
    }
  }

  &-primary {
    background: var(--bs-primary);

    svg path {
      stroke: #fff;
    }
  }

  &-readed {
    background: #F9FAFB;

    svg path {
      stroke: #B6BEC9;
    }
  }
}
.custom-scroll {
  //gap: 1.5rem;
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
}
.custom-scroll::-webkit-scrollbar {
  height: 0;
}
.custom-scroll.active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
