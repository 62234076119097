@media screen and (max-width: $mobile-breakpoint) {
  .custom-toast {
    min-height: 7.2rem !important;
    min-width: unset;
    padding: 0 2rem;
    margin: 0 16px;
    &.top-right {
      top: 1.6rem;
      right: 0;
    }
    &.top-left {
      top: 1.6rem;
      left: 0;
    }
    &.bottom-left {
      bottom: 1.6rem;
      left: 0;
    }
    &.bottom-right {
      bottom: 1.6rem;
      right: 0;
    }
  }
  .toast-message {
    font-size: 13px;
  }


}
@media screen and (max-width: 769px){
  .custom-toast {
    min-width: calc(100vw - 32px);
  }
}