@media screen and (max-width: $mobile-breakpoint) {
  .filter {
    &-item {
      &.filter-amount {
        width: 100%;

        .input-wrapper-lg {
          input {
            font-size: 1.4rem;

            &::placeholder {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }

  .c-dropdown-filter .dropdown-menu {
    left: 1.6rem !important;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.25);
    z-index: 998;
    display: none;
  }
  #filter-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $body-background;
    z-index: 999999;
    transform: translateY(100%);
    opacity: 0;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;

    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    padding-top: 1.6rem;
    &.isShow {
      transform: translateY(0);
      opacity: 1;
    }

    .filter {
      &-header {
        padding: 2.4rem $padding-mobile;
        position: relative;
      }

      &-title {
        @include centerAbsolute('both')
      }

      &-btn {
        height: 4rem;
        padding: 0 1.6rem;
        font-weight: 500;
        @include flex(center, space-between);
      }

      &-body {
        padding: 1.6rem $padding-mobile 2.4rem;
      }

      &-wrapper {
        &.isShow {
          .filter-menu {
            transform: translateY(0);
            opacity: 1;
          }

          .overlay {
            display: block;
          }
        }
      }

      &-item {
        width: 100%;
        margin: 0;

        & + .filter-item {
          margin-top: 3.2rem;
        }
      }

      &-header {
        padding: 0 $padding-mobile;
      }
      &-action-block {
        margin-left: $padding-mobile;
        margin-right: $padding-mobile;
      }
      &-menu {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #FDFDFD;
        border-radius: 12px 12px 0px 0px;
        padding: $padding-mobile 0 10rem;
        z-index: 999;
        transform: translateY(100%);
        opacity: 0;
        t-webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;

        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
      }

      &-image {
        @include size(2.4rem);

        img {
          @include size(100%);
        }
      }

      &-options {
        max-height: 30rem;
        overflow-y: auto;
        padding-top: 1.4rem;
      }

      &-option {
        padding: 0.7rem 1.2rem;
        border-radius: 0.4rem;
        min-height: 4rem;
        border: 1px solid transparent;
        margin: 0 $padding-mobile;

        &.active {
          border-color: var(--bs-dark);
          color: var(--bs-dark);
        }

        & + .filter-option {
          margin-top: 0.8rem;
        }
      }

      &-search {
        margin: 1rem 0 1rem;
        height: 4rem;
        position: relative;

        .search-icon {
          position: absolute;
          top: 50%;
          left: 1.6rem;
          transform: translateY(-50%);
        }

        input {
          width: 100%;
          padding: 0.8rem 1.6rem 0.8rem 4.4rem;
          border: 1px solid $border-color;
          border-radius: 4px;
        }
      }

      &-btn {
        & > * {
          user-select: none;
          pointer-events: none;
        }
      }
    }

    .c-dropdown-filter {
      .dropdown-toggle {
        padding: 0;

        &::after {
          pointer-events: none;
          user-select: none;
        }
      }

      .dropdown-menu {
        display: none;
      }
    }
  }
}
