@media screen and (max-width: $mobile-breakpoint) {
  // Dropdown
  .c-dropdown-coins,
  .c-dropdown-notification {
    .btn-content {
      span {
        font-size: 2rem !important;
      }

      svg {
        width: 2rem !important;
        height: 2rem !important;
      }
    }
  }

  .c-dropdown.c-dropdown-notification,
  .c-dropdown.c-dropdown-coins {
    .dropdown-menu {
      left: unset !important;
      width: calc(100vw - 32px);
      min-width: calc(100vw - 32px);
      transform: translateX(-0) !important;
    }
  }
  .c-dropdown.c-dropdown-coins {
    .dropdown-menu {
      right: -4rem !important;
    }
  }
  .c-dropdown.c-dropdown-notification {
    .dropdown-menu {
      right: -8.8rem !important;
      min-width: calc(100vw - 32px);
      li {
        .dropdown-item {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

//  Datepicker
  .custom-datepicker {
    .overlay {
      display: block;
    }
    .calendar-icon {
      .refresh-calendar-btn {
        display: block;
      }
    }
  }
  .dp__arrow_bottom {
    display: none;
  }
  .dp__menu.dp__menu_index.dp__theme_light {
    z-index: 999999;
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    z-index: 9999999;
    transform: translate(-50%, -50%) !important;
    //&::after {
    //  position: absolute;
    //  top: 50%;
    //  left: 50%;
    //  transform: translate(-50%, -50%);
    //  width: 100vw;
    //  height: 100vh;
    //  content: '';
    //  background-color: rgba(0, 0, 0, 0.2);
    //}
  }
  .dp__arrow_top {
    display: none;
  }
  .custom-accordion .accordion-body {
    padding: $padding-mobile;
  }
  .custom-tab {
    &-body {
      .loader-container {
        min-height: 30rem;
      }
    }
  }
}
@media (min-width: 900px) and (max-width: $tablet-breakpoint) {
  .table.custom-table td {
    &:first-child {
      padding-left: 1.6rem;
    }
    &:last-child {
      padding-right: 1.6rem;
    }
  }
}
