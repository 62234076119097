$desktop-container: 1440px;
$desktop-container-sm: 1200px;
$tablet-container-sm: 944px;

$header-height: 6.4rem;
$footer-height: 27.6rem;
$banner-height: 395px;
$header-sub-height: 134px;
$button-height: 4rem;
$button-min-width: 12rem;
$padding-mobile: 1.6rem;
$mobile-font: 1.2rem;

$mobile-breakpoint: 899px;
$tablet-breakpoint: 1025px;
$desktop-breakpoint: 1240px;

$modal-width: 1200px;
$border-radius: 0.8rem;
$btn-radius: 0.8rem;
$border-color: #E6E9EC;
$border-dark: #2B323B;
$body-background: #FDFDFD;

$c-bg-success: #ECFDF5;
$c-bg-danger: #FEF4F2;
$c-success-dark: #059669;
$c-success: #059669;
$c-success-2: #34D399;

$c-gray-40: #B6BEC9;

$c-warning: #D97706;
$c-danger: #DC3E26;

$c-toast-warning: #FDE68A;
$c-toast-warning-bg: #FFFBEB;
$c-toast-warning-icon: #F59E0B;
$c-toast-warning-text: #78350F;

$c-toast-error: #FED1CA;
$c-toast-error-bg: #FEF4F2;
$c-toast-error-icon: #EF5B44;
$c-toast-error-text: #7F2A1D;

$c-toast-info: #BFDBFE;
$c-toast-info-bg: #EFF6FF;
$c-toast-info-icon: #3B82F6;
$c-toast-info-text: #1E3A8A;

$c-toast-success: #A7F3D0;
;
$c-toast-success-bg: #ECFDF5;
$c-toast-success-icon: #10B981;
$c-toast-success-text: #064E3B;
