#footer {
  min-height: $footer-height;
  background-color: var(--bs-dark);
  width: 100%;
  .c-dropdown {
    margin-left: 1.6rem;
    .dropdown-menu {
      left: 50% !important;
    }
    .dropdown-toggle {
      background-color: #38414C;
      border-radius: $border-radius;
      padding: 0.75rem 1.6rem;
    }
  }
}

.footer {
  &-content {
    padding: 4rem 0;
  }

  &-top {
    border-bottom: 1px solid #2B323B;
  }
  &-option {
    margin-left: 4rem;
  }

  &-menu {
    li {
      margin-top: 1.8rem;
    }

    &-link {
      color: var(--bs-body-color);
      font-weight: 600;
      padding: 0.5rem 0;
    }
  }
}

.socials {
  li + li {
    margin-left: 1.6rem;
  }
}

.social {
  &-link {
    color: var(--bs-body-color);
    font-size: 2.2rem;
    padding: 0.5rem;
  }
}