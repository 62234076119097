@media screen and (max-width: $mobile-breakpoint) {
  .mobile-menu-header {
    padding: 0 $padding-mobile;
  }
  .mobile-menu-body {
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    padding: 0 $padding-mobile 2rem;
    &::-webkit-scrollbar {
      width: 5px !important;
      position: absolute;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #bbb;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--bs-light);
    }
  }
  #mobile-menu {
    &.show {
      .mobile-menu-wrapper {
        transform: translateX(0);
        opacity: 1;
      }
      .overlay {
        display: block;
      }
    }
  }
  .mobile-menu-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 30rem;
    height: 100vh;
    background-color: var(--bs-dark);
    z-index: 99999999;
    padding: $padding-mobile 0;
    transform: translateX(100%);
    opacity: 0;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;

    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  .menu {
    &-item {
      & + .menu-item {
        margin-top: 1.6rem;
      }

      &.isShow {
        .menu-item-icon {
          transform: rotate(180deg);
        }

        .menu-item-content {
          height: auto;
          transform: translateY(0);
          z-index: 97;
        }
      }

      &-content {
        height: 0;
        overflow: hidden;
        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;

        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
      }

      &-icon {
        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;

        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
      }

      &-button {
        padding: 1.2rem 0;
        @include flex(center, space-between);
        background-color: var(--bs-dark);
        z-index: 99;
        & > * {
          user-select: none;
          pointer-events: none;
        }
        & > .user-info-block {
          user-select: auto;
          pointer-events: auto;
        }
      }
    }
  }

  .submenu-item {
    @include flex(center, flex-start);
    padding: 1.6rem $padding-mobile;
    max-height: 5.6rem;
    &-image {
      @include size(2rem);
    }

    &.isActive {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #01102F;
      border-radius: 4px;

      svg path {
        fill: $body-background
      }
    }

    & + .submenu-item {
      margin-top: 0.4rem;
    }
  }
  .header-menu-right {
    height: $header-height;
  }
}
@media screen and (max-width: $tablet-breakpoint){
  .header-menu {
    display: none !important;
  }
}