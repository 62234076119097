.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 80px;
  background: rgba(0, 0, 0, 0.25);
  display: none;
  z-index: 999999;

  &.isShow {
    display: flex;

    .notification-modal {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: $border-radius;
    }
  }

  .modal {
    &-content {
      position: relative;
      margin: auto;
      max-width: $modal-width;
      background-color: #fff;
      border-radius: $border-radius;
      padding: 3.2rem;
      opacity: 0;
      -webkit-animation: showModal 0.25s ease-in-out forwards;
      -moz-animation: showModal 0.25s ease-in-out forwards;
      -o-animation: showModal 0.25s ease-in-out forwards;
      animation: showModal 0.25s ease-in-out forwards;

      &-line {
        &::after {
          position: absolute;
          top: 0;
          left: 50%;
          content: '';
          transform: translateX(-50%);
          @include size(1px, 100%);
          background-color: $border-color;
        }
      }
    }

    &-col-left {
      padding-right: 4rem;
    }

    &-col-right {
      padding-left: 4rem;
    }
  }
}

@keyframes showModal {
  from {
    opacity: 0;
    top: 0;
  }
  to {
    opacity: 1;
    top: 46px;
  }
}

.close-modal-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;

  span {
    font-size: 2.5rem;
  }
}

.modal-title {
  letter-spacing: -0.5px;
  font-size: 2.4rem;
}

#VerifyKycModal,
#CreateMerchantNameModal,
#NotificationModal {
  .modal-content {
    width: 40.3rem;
    min-height: unset !important;
    max-width: calc(100vw - 32px);
    padding: 4rem;

    &-xl {
      width: 48.8rem;
    }
  }
}

#VerifyKycModal,
#CreateMerchantNameModal, {
  .custom-modal {
    .modal-content {
      padding: 2.4rem;
    }
  }
}

#CreatePaymentMethodModal,
#EditPaymentMethodModal {
  .modal-content {
    width: 56.8rem;
    max-width: calc(100vw - 32px);
  }
}

#TheConfirmTransactionModal,
#TheQuickBuySellModal,
#The2FAModal,
#TheCancelOrderModal,
#TheAppealOrderModal,
#TheConfirmModal,
#ChoosePaymentMethodModal,
#TheWithdrawCoinModal {
  .modal-body-inner {
    padding: 16px;
  }
  .modal-content {
    width: 58.8rem;
    max-width: calc(100vw - 32px);

    &-sm {
      width: 40.3rem !important;
    }
    &-md {
      width: 87.3rem !important;
    }
  }
}

#TheBuyModal,
#TheSellModal {
  .modal-content {
    min-height: 49rem;
  }
}

#The2FAModal {
  .modal-content {
    min-height: auto;
  }
}

#TheConfirmModal {
  .confirm {
    &-body {
      padding: 3.2rem 0;
      //margin: 3.2rem 0;
      //border-top: 1px solid $border-color;
    }

    &-row {
      & + .confirm-row {
        margin-top: 3.2rem;
      }

      ol {
        padding-left: 0;
        margin-bottom: 0;

        li {
          text-align: right;
        }
      }
    }

    &-item {
      width: calc(33.333% - 16px);
      text-align: left;
    }

    &-item-haft {
      width: calc(50% - 17px);
      text-align: left;
    }
  }
}

#TheAddBlackListModal {
  .modal-content {
    width: 59.4rem;
    max-width: calc(100vw - 32px);
  }
}

#ChoosePaymentMethodModal,#TheWithdrawCoinModal {
  .modal-wrapper {
    min-height: 46rem;

    .loader-container {
      @include centerAbsolute('both');
    }
  }
}

#ChoosePaymentMethodModal.payment-method-sell,
#TheCancelOrderModal,
#TheAppealOrderModal,
#TheQuickBuySellModal {
  .modal-wrapper {
    background-color: $body-background;
    padding: 4rem;
    border-radius: $border-radius;
  }

  .custom-modal {
    max-height: 100vh;
    overflow-y: auto;
  }

  .modal-content {
    //transform: translateX(-50%);
    padding: 0 0 5rem 0;
    background-color: transparent !important;
    //animation: showModalFixed 0.25s ease-in-out forwards;
  }

  &::-webkit-scrollbar {
    width: 5px !important;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $c-gray-40;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--bs-light);
  }
}

#TheAddBlackListModal {
  .c-form-group {
    .input-wrapper {
      input {
        &::placeholder {
          color: #B6BEC9 !important;
        }
      }
    }
  }
}

//@keyframes showModalFixed {
//  from {
//    opacity: 0;
//    top: 0;
//  }
//  to {
//    opacity: 1;
//    top: 5rem;
//  }
//}

.grecaptcha-badge {
  display: none !important;
}
