@media screen and (max-width: $mobile-breakpoint) {
  .create-form-wrapper {
    margin: 0 -16px;
    padding: 0 $padding-mobile;
  }
  .card-row-title {
    display: none !important;
  }
  .card {
    &-row {
      &-sm {
        @include flex(center, space-between);

        & + .card-row-sm {
          margin-top: 1.2rem;
        }
      }
      &-body {
        padding: 2.4rem 0;
      }
    }
  }
  .btn-action {
    @include size(4rem);
    @include flex(center, center);
  }
  .show-action-btn {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;

    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    &.isShow {
      transform: rotate(180deg);
    }
  }
  .back-btn {
    position: relative;
  }
  #edit-a4v {
    .edit-summary {
      flex-wrap: wrap;
      width: 100%;
      height: auto;
      &-item {
        width: 100%;
        & + .edit-summary-item {
          margin-top: $padding-mobile;
        }
      }
    }
  }
  .steps-bar {
    padding: 0 $padding-mobile;
  }
  .step {
    &-number {
      @include size(2.4rem);
      font-size: 1.2rem;
    }
    &-title {
      font-size: 1.2rem;
      width: 7.6rem;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
  .create-post-form {
    padding: 0;
    border: 0;
  }
  .payment-method-option {
    width: calc(100vw - 32px);
  }
  .asset-option {
    margin-bottom: 0;
    & + .asset-option {
      margin-top: 0.8rem;
    }
  }
  #TheListOrderByAdv {
    .card {
      border: 0;
      padding: 0;
    }
    .no-data {
      border-top: 1px solid $border-color;
    }
    .order-by-adv-detail {
      &::before,
      &::after {
        display: none;
      }
      &-col {
        width: 100%;
        &:first-child {
          padding-right: 0;
        }
        &:nth-child(2) {
          padding-right: 0;
          padding-left: 0;
        }
        &:last-child {
          padding-left: 0;
        }
      }
    }
    .list-order-by-adv {
      margin-top: 0 !important;
    }
  }
}

@media (min-width: 900px) and (max-width: $desktop-breakpoint) {
  .filter-item-time {
    width: 24rem;
  }
  .card-col-md {
    &-none {
      display: none;
    }
    &-xs {
      width: 8%;
    }
    &-sm {
      width: 15%;
    }
    &-md {
      width: 20%;
    }
    &-xl {
      width: 24%;
    }
    &-xxl {
      width: 40%;
    }
  }
  .payment-method-md {
    border-radius: 0.4rem;
    padding: 0.6rem 0.8rem 0.6rem 1.6rem;
    max-height: 2.4rem;
    & + .payment-method-md {
      margin-left: 1.2rem;
    }
    .highlight-2 {
      left: 0.8rem;
    }
  }
}
@media (min-width: 900px) and (max-width: $tablet-breakpoint) {
  .c-dropdown-filter-2 {
    min-width: 18rem;
  }
  .filter-item {
    width: 18rem;
  }
  .card {
    &-col {
      align-items: start;
      &-md,
      &-xl {
        align-items: start;
      }
      &-sm {
        width: 10%;
        & + .card-col-sm {
          padding-left: 1rem;
        }
      }
    }
    &-row {
      max-height: unset;
      &-title,
      &-body {
        padding: 2.4rem 1.6rem;
      }
    }
  }
}

@media (min-width: 900px) and (max-width: $tablet-breakpoint) {
  #TheListOrderByAdv {
    .card {
      padding: $padding-mobile;
    }
    .order-by-adv-detail {
      &::before {
        left: 30%;
      }
      &:after {
        left: calc(30% + 318px);
      }
      &-col {
        &:first-child,
        &:last-child {
          width: 30%;
        }
        &:nth-child(2) {
          flex-grow: 1;
        }
      }
    }
  }
}