@media screen and (max-width: $mobile-breakpoint) {
  [data-aos^=zoom][data-aos^=zoom],
  [data-aos^=fade][data-aos^=fade] {
    opacity: 1;
    transform: unset;
  }
  // Font weight
  .fw-sm-500 {
    font-weight: 500 !important;
  }
  .fw-sm-600 {
    font-weight: 600 !important;
  }

  // Font size
  .fs-sm {
    font-size: 1.2rem !important;
  }
  .fs-md {
    font-size: 1.4rem !important;
  }
  .fs-lg {
    font-size: 1.6rem !important;
  }
  .fs-xl {
    font-size: 2rem !important;
  }
  .fs-xxl {
    font-size: 2.4rem !important;
  }
  .fs-sm-18 {
    font-size: 1.8rem !important;
  }
  // Font color
  .text-sm-dark {
    color: var(--bs-dark) !important;
  }

  // Spacing
  .pt-sm-24 {
    padding-top: 2.4rem !important;
  }
  .pb-sm-16 {
    padding-bottom: 1.6rem !important;
  }
  .mt-sm-24 {
    margin-top: 2.4rem !important;
  }
  .mb-sm-24 {
    margin-bottom: 2.4rem !important;
  }
  .mt-sm-12 {
    margin-top: 1.2rem;
  }
  .mb-sm-16 {
    margin-bottom: 1.6rem !important;
  }
  .mt-sm-16 {
    margin-top: 1.6rem !important;
  }
  .mb-mobile {
    margin-bottom: 8rem !important;
  }
  .me-8 {
    margin-right: 0.8rem;
  }
  .ms-8 {
    margin-left: 0.8rem;
  }
  .error {
    font-size: 1.2rem !important;
  }
  .mt-32 {
    margin-top: 2.4rem;
  }
  .mt-48 {
    margin-top: 4rem;
  }
  .mt-sm-12 {
    margin-top: 1.2rem;
  }
  .mt-sm-32 {
    margin-top: 3.2rem !important;
  }
  .mt-sm-48 {
    margin-top: 4.8rem !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-35 {
    width: 35% !important;
  }
  .padding-mobile {
    padding: 0 $padding-mobile;
  }
  .page-heading {
    position: relative;
    top: unset;
    left: 0;
    transform: unset;
    font-size: 2.4rem !important;
    margin-top: 1.6rem;
  }
  .heading-mobile {
    text-align: left !important;

    & > p {
      font-size: 2.8rem !important;
    }

    &-sm {
      text-align: left !important;

      & > p {
        font-size: 2.4rem !important;
      }
    }
  }

  //  Border
  .border-dark {
    border-color: $border-dark !important;
  }

  .line-sm-1 {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
  }
  .border-top-sm {
    border-top: 1px solid $border-color;
  }
  .w-xs-50 {
    width: 50% !important;
  }
}

@media (min-width: 900px) and (max-width: $desktop-breakpoint) {
  .w-md-100 {
    width: 100% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .mt-md-16 {
    margin-top: 1.6rem !important;
  }
  body {
    font-size: 14px;
  }
  .border-md-0 {
    border: 0 !important;
  }
  .w-md-28 {
    width: 30% !important;
  }
}
@media (min-width: 900px) and (max-width: $tablet-breakpoint) {
  .mt-lg-16 {
    margin-top: 1.6rem !important;
  }
}
@media screen and (min-width: 900px){
  .mt-md-24 {
    margin-top: 2.4rem;
  }
}
@media screen and (min-width: $tablet-breakpoint){
  .w-xl-50 {
    width: 50% !important;
  }
  .mt-lg-24 {
    margin-top: 2.4rem !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
}

