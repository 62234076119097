.card {
  border: 1px solid $border-color;
  padding: 2.4rem;
  border-radius: $border-radius;
  width: 100%;
  &-overview {
    min-height: 36.6rem;
    width: calc(50% - 12px);
  }
  &-sm {
    border-radius: $border-radius;
  }
}

.custom-tab-asset {
  &-2 {
    order: 2;
  }
  .nav-tabs {
    border: 0;
    overflow-x: auto;
    flex-wrap: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    .nav-item {
      margin-right: 2.4rem;
      white-space: nowrap;
      &:hover {
        .nav-link {
          color: var(--bs-primary);
          border-color: var(--bs-primary);
          background-color: transparent;
        }
      }
    }
    .nav-link {
      border-radius: 0;
      border: 0;
      border-bottom: 2px solid transparent;
      font-weight: 600;
      padding: 1.2rem 0 1rem;
      line-height: 1.6rem;
    }
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-dark);
    border-color: var(--bs-primary);
    background-color: transparent;
  }
  .tab-content {
    margin-top: 2.4rem;
    padding: 0;
  }
}
.intro-text {
  line-height: 2.4rem;
  width: 70rem;
  max-width: 100%;
}
.current-method {
  &-list {
    @include flex(flex-start, flex-start, row, wrap);
  }
  &-option {
    width: 100%;
    margin: 2.2rem 0 0;
    padding: 2rem 2rem;
    border: 1px solid $border-color;
    border-radius: $border-radius;
  }
}
.add-payment-method-form {
  min-height: 17rem;
  .loader-container {
    min-height: 17rem;
  }
}
#ChoosePaymentMethodModal,
#TheWithdrawCoinModal,
#EditPaymentMethodModal {
  .input-small {
    padding: 0 2rem;
  }
}
#overview {
  .input-wrapper {
    input {
      &::placeholder {
        color: #9FA9B7 !important;
        font-weight: 500;
      }
    }
  }
  .custom-datepicker {
    .dp__input {
      &::placeholder {
        color:  #758499 !important;
      }
    }
  }
}
.blacklist {
  &-card {
    th {
      padding-top: 0;
    }
  }
}
