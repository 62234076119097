// Dropdown
.multiselect__content-wrapper {
  &::-webkit-scrollbar {
    width: 5px !important;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #bbb;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--bs-light);
  }
}

.c-dropdown {
  .btn-content {
    &:focus-visible {
      outline: 0;
    }
  }

  &:focus-visible {
    outline: 0;
    border: 0;
    --webkit-ouline: 0;
    --webkit-border: 0;
  }

  .dropdown-toggle {
    background-color: transparent;
    border: 0;
    color: #fff;
    font-size: 1.4rem;
    font-weight: 600;

    &:focus-visible {
      outline: 0;
      border: 0;
      --webkit-ouline: 0;
      --webkit-border: 0;
    }

    &:active,
    &:focus {
      color: #fff;
      background-color: transparent;
    }

    &::after {
      margin-left: 8px;
      border-width: 0.5rem !important;
      -webkit-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      transition: all 0.25s ease;
    }

    &:hover {
      background-color: transparent;
      color: #fff;
    }

    &.show {
      &::after {
        transform: rotate(-180deg);
      }
    }
  }

  .dropdown-menu {
    border: 0;
    outline: 0;
    right: 0;
    left: unset !important;
    background-color: #fdfdfd;
    box-shadow: 0px 12px 32px rgba(34, 34, 57, 0.08);
    padding: 1.6rem;
    border-radius: $border-radius;

    li {
      font-size: 1.4rem;
      border-radius: 0.4rem;
      -webkit-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      transition: all 0.25s ease;
      border: 1px solid transparent;

      & + li {
        margin-top: 0.4rem;
      }

      .dropdown-item {
        padding: 0.8rem 1rem;
        border-radius: 0.4rem;
        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
      }

      &:hover {
        background-color: transparent !important;
        border-color: var(--bs-dark);

        .dropdown-item {
          color: var(--bs-dark);
          background-color: transparent !important;
        }
      }

      &.active {
        border-color: var(--bs-dark);
        background-color: transparent !important;
        user-select: none;
        pointer-events: none;

        .dropdown-item {
          color: var(--bs-dark);
        }
      }

      .dropdown-item {
        color: var(--bs-body-color);
        font-weight: 600;
        @include flex(center, flex-start);

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .dropdown-image {
    @include size(24px);
    margin-right: 0.8rem;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }
  }
}

.c-dropdown-2 {
  .dropdown-toggle {
    background-color: $c-gray;
    padding: 1rem 1.6rem;
    border-radius: $border-radius;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;

    &:hover {
      opacity: 0.8;
      background-color: $c-gray;
    }
  }

  .dropdown-menu {
    background-color: var(--bs-darker);

    li {
      .dropdown-item {
        color: #fff;
      }

      &:hover {
        background-color: var(--bs-dark);
      }

      &.active {
        background-color: var(--bs-dark);
      }
    }
  }
}

.c-dropdown-3 {
  .dropdown-toggle {
    width: fit-content;
    color: var(--bs-body-color);
    border: 1px solid $border-color;
    border-radius: $border-radius;
    padding: 0 2.35rem;
    height: 4rem;
    display: block;
    font-weight: 600;
    line-height: 1.6rem;
    cursor: pointer;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    @include flex(center, center);

    &.show {
      border-color: var(--bs-dark) !important;

      &::after {
        border-top-color: var(--bs-dark) !important;
      }

      svg path {
        fill: var(--bs-dark) !important;
      }

      span {
        color: var(--bs-dark) !important;
      }
    }

    &:hover {
      color: var(--bs-body-color);
      border-color: $border-color;
    }

    &::after {
      border-top-color: var(--bs-body-color) !important;
    }
  }

  .dropdown-menu {
    min-width: 100%;
    background-color: #fff;
    box-shadow: 0px 12px 64px rgba(0, 0, 0, 0.1);

    li {
      .dropdown-item {
        color: var(--bs-body-color);
        padding: 0.95rem 2rem;
      }

      &:hover {
        .dropdown-item {
          background-color: var(--bs-light);
          color: var(--bs-dark);
        }
      }

      &.active {
        .dropdown-item {
          //background-color: var(--bs-light);
          color: var(--bs-dark);
        }
      }
    }
  }
}

.dropdown-language {
  &.c-dropdown .dropdown-menu li .dropdown-item {
    padding: 0.95rem 1.2rem;
  }

  .dropdown-image {
    @include size(28px, 20px);

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.c-dropdown-4 {
  .dropdown-menu {
    min-width: 32rem;

    li {
      & + li {
        margin-top: 0.4rem;
      }

      .dropdown-item {
        padding: 0;
        @include flex(center, flex-start);
        a {
          width: 100%;
          min-width: 28.6rem;
          padding: 0.9rem 1.6rem;
          div {
            flex: 1;
          }
        }
      }

      &.active {
        .dropdown-icon {
          color: var(--bs-primary);

          path {
            fill: var(--bs-primary);
          }
        }
      }

      &:hover {
        .dropdown-icon {
          color: var(--bs-primary);

          path {
            fill: var(--bs-primary);
          }
        }
      }
    }
  }

  .dropdown-icon {
    color: #b6bec9;
    margin-right: 1.6rem;
    display: inline-block;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;

    path {
      -webkit-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      transition: all 0.25s ease;
      fill: #b6bec9;
    }
  }
}

.c-dropdown-icon {
  .dropdown-toggle {
    &.show {
      .btn-content {
        span {
          color: #fff !important;
        }
      }
    }

    &::after {
      display: none;
    }
  }
}

.c-dropdown-notification {
  .loader-2 {
    position: absolute;
    bottom: 0;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
  }

  .notification-content {
    max-height: 30rem;
    height: 30rem;
    max-width: 100%;
    overflow-y: auto;
    padding: 0 2.4rem;
    position: relative;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #ddd;
      border-radius: 0;
    }
  }

  .dropdown-title {
    padding: 0 2.4rem;
  }
  .dropdown-toggle {
    padding: 0.4rem 1.4rem;
  }
  .dropdown-menu {
    min-width: 42rem;
    max-width: 100vw;
    left: 50% !important;
    top: 100% !important;
    transform: translateX(-50%) !important;
    padding: 2.4rem 0;
    z-index: 99999;

    li {
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
      border-top: 1px solid #f4f5f6;
      position: relative;
      .dropdown-item {
        padding: 1.5rem 0;
      }
      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        content: "";
        @include size(8px);
        background-color: var(--bs-primary);
        border-radius: 50%;
      }

      &:hover {
        border-color: transparent;
        border-top-color: #f4f5f6;

        .dropdown-item {
          background-color: transparent !important;
        }
      }

      &.read {
        .dropdown-item {
          opacity: 0.9;
          .btn-icon-sm {
            background-color: #f4f5f6;
          }

          span {
            color: var(--bs-body-color) !important;
          }
        }

        &::after {
          background-color: var(--bs-body-color);
        }
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.c-dropdown.c-dropdown-coins {
  .dropdown-toggle {
    padding: 0.4rem 1.4rem !important;
  }
  .search-input {
    border: 1px solid $border-color;
    border-radius: 0.4rem;
    margin-bottom: 1.6rem;
    padding: 0.5rem 1.5rem;
    height: 4rem;
    @include flex(center, space-between);

    .search-icon {
      flex-shrink: 0;
      margin-right: 0.5rem;
    }

    input {
      border: 0;
      outline: 0;
      flex-grow: 1;

      &::placeholder {
        color: #b6bec9;
      }
    }
  }
  .coins-list-search {
    padding: 1.6rem 1.6rem 0;
  }
  .coins-list-wrapper {
    padding: 0 1.6rem;
    overflow-y: auto;
    max-height: 24.5rem;
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #ddd;
      border-radius: 0;
    }
  }

  .dropdown-title {
    padding: 0 1.6rem;
  }

  &.isLoading {
    .dropdown-coins {
      min-height: 16.7rem;
    }
  }

  .dropdown-toggle {
    padding-left: 0.5rem;
  }

  .dropdown-menu {
    min-width: 40rem;
    max-width: 100%;
    left: 50% !important;
    top: 100% !important;
    transform: translateX(-50%) !important;
    padding: 2.4rem 0;
    z-index: 99999;

    li {
      cursor: unset;
      user-select: none;
      pointer-events: none;
      //padding-bottom: 0.8rem;
      border-bottom: 1px solid $border-color;
      border-left: 1px solid $border-color;
      border-radius: $border-radius;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
      & + li {
        margin-top: 0.8rem !important;
      }

      .dropdown-item {
        justify-content: space-between;
        padding: 0.95rem 1rem;
      }

      &:last-child {
        .dropdown-item {
          padding-bottom: 1rem;
        }
      }
    }
  }
}

.c-dropdown-user {
  .dropdown-menu {
    left: 50% !important;
    top: 100% !important;
    transform: translateX(-50%) !important;
    padding: 1.6rem;
    min-width: 288px;
    z-index: 99999;

    li {
      &.active {
        background-color: #f4f5f6;

        .dropdown-item {
          svg path {
            fill: var(--bs-primary) !important;
          }

          span {
            color: var(--bs-dark) !important;
          }
        }
      }

      .dropdown-item {
        padding: 0;
        a {
          min-width: 24rem;
          max-width: 100%;
          padding: 0.9rem 1.2rem;
          span {
            flex-grow: 1;
          }
        }
        svg path {
          -webkit-transition: all 0.25s ease;
          -moz-transition: all 0.25s ease;
          -o-transition: all 0.25s ease;
          transition: all 0.25s ease;
        }
      }

      .dropdown-item span {
        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
      }

      &:hover {
        background-color: #f4f5f6;

        .dropdown-item {
          svg path {
            fill: var(--bs-primary) !important;
          }

          span {
            color: var(--bs-dark) !important;
          }
        }
      }
    }
  }
}

.c-dropdown-filter {
  .dropdown-toggle {
    border-radius: $border-radius !important;
    padding: 0 1.6rem;
    cursor: pointer;
    height: 4rem;
    border: 1px solid $border-color;

    &:hover {
      border: 1px solid $border-color;
    }

    &.show {
      border-color: var(--bs-dark);

      &::after {
        transform: translateY(-50%) rotate(-180deg) !important;
      }
    }

    .btn-content {
      width: 100%;
    }

    &::after {
      border-top-color: var(--bs-body-color);
      border-width: 0.6rem !important;
      right: 1.5rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .dropdown-menu {
    min-width: 282px;
    left: 50% !important;
    box-shadow: 0px 8px 32px rgba(33, 37, 44, 0.1);

    .dropdown-item {
      -webkit-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      transition: all 0.25s ease;
      border-radius: $border-radius;
    }

    li {
      &.active {
        background-color: var(--bs-light);
      }

      &:hover {
        .dropdown-item {
          background-color: var(--bs-light);
        }
      }

      .dropdown-item {
        height: 4rem;
      }

      .dropdown-image {
        img {
          border-radius: 50%;
        }
      }
    }
  }

  .dropdown-search {
    position: relative;
    background-color: transparent;
    border-radius: 0.4rem;
    border: 1px solid $border-color;
    height: 4rem;
    margin: 1.2rem 0;

    input {
      border: 0;
      @include size(100%);
      padding: 0.8rem 1rem 0.8rem 4.2rem;
      background-color: transparent;
      outline: 0;
    }

    .search-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1rem;
      color: var(--bs-body-color);
    }
  }

  &-large {
    .dropdown-toggle {
      border: 1px solid $border-color !important;
      height: 6rem !important;

      &.show {
        border-color: var(--bs-dark) !important;

        &::after {
          border-top-color: var(--bs-dark);
        }
      }
    }
  }
}

.c-dropdown-filter-1 {
  .dropdown-toggle {
    border-radius: $border-radius !important;
    padding: 0 1rem;
    cursor: pointer;
    height: 4rem;
    border: 1px solid $border-color;

    &:hover {
      border: 1px solid $border-color;
    }

    &.show {
      border-color: var(--bs-dark);

      &::after {
        transform: translateY(-50%) rotate(-180deg) !important;
      }
    }

    .btn-content {
      width: 100%;
    }

    &::after {
      border-top-color: var(--bs-body-color);
      border-width: 0.6rem !important;
      right: 1.5rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: none;
    }
  }

  .dropdown-menu {
    min-width: 282px;
    left: 50% !important;
    box-shadow: 0px 8px 32px rgba(33, 37, 44, 0.1);

    .dropdown-item {
      -webkit-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      transition: all 0.25s ease;
      border-radius: $border-radius;
    }

    li {
      &.active {
        background-color: var(--bs-light);
      }

      &:hover {
        .dropdown-item {
          background-color: var(--bs-light);
        }
      }

      .dropdown-item {
        height: 4rem;
      }

      .dropdown-image {
        img {
          border-radius: 50%;
        }
      }
    }
  }

  .dropdown-search {
    position: relative;
    background-color: transparent;
    border-radius: 0.4rem;
    border: 1px solid $border-color;
    height: 4rem;
    margin: 1.2rem 0;

    input {
      border: 0;
      @include size(100%);
      padding: 0.8rem 1rem 0.8rem 4.2rem;
      background-color: transparent;
      outline: 0;
    }

    .search-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1rem;
      color: var(--bs-body-color);
    }
  }

  &-large {
    .dropdown-toggle {
      border: 1px solid $border-color !important;
      height: 6rem !important;

      &.show {
        border-color: var(--bs-dark) !important;

        &::after {
          border-top-color: var(--bs-dark);
        }
      }
    }
  }
}

.c-dropdown-filter-2 {
  min-width: 20rem;

  .dropdown-menu {
    li {
      .dropdown-item {
        padding: 1.2rem;
      }
    }
  }
}

.c-dropdown-qrcode {
  .dropdown-menu {
    width: 20.8rem;
  }

  .dropdown-toggle {
    &.show {
      .btn-icon-circle {
        border-color: var(--bs-dark) !important;

        svg path {
          fill: var(--bs-dark) !important;
        }
      }
    }
  }
}

.c-dropdown-select {
  .dropdown-toggle {
    width: 100%;
    justify-content: space-between;
    height: 6rem;
  }
}

// Custom vue-multiselect
@import "~vue-multiselect/dist/vue-multiselect.css";

.custom-vue-multiselect {
  border-radius: $border-radius;

  .multiselect__option {
    line-height: unset;
  }

  .multiselect__tags {
    padding-top: 1rem;
    padding-left: 0.5rem;
    border-width: 1px;
    border-radius: $border-radius !important;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;

    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;

    & > * {
      transition: all 0.25s ease;
    }
  }

  .multiselect__content-wrapper {
    border-width: 0;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.08);
  }

  .multiselect__single,
  .multiselect__input {
    margin-bottom: 1rem;
  }

  .multiselect__select {
    height: 100%;

    &::before {
      top: 56%;
      margin-top: unset;
    }
  }

  .option__image {
    display: inline-block;
    @include size(28px, 20px);
  }

  .option__title {
    color: var(--bs-gray-600);
  }

  .multiselect__option--highlight {
    background: #f3f3f3;
    color: inherit;
  }

  .multiselect__option--selected,
  .multiselect__option--selected.multiselect__option--highlight {
    background: var(--bs-light);
    color: #fff;

    .option__title {
      color: var(--bs-dark);
    }
  }
}

.multiselect--above .multiselect__content-wrapper {
  bottom: calc(100% + 20px) !important;
  border-radius: $border-radius !important;
  padding: 1rem;
}

.multiselect__element + .multiselect__element {
  margin-top: 0.5rem;
}

.multiselect__element span {
  border-radius: $border-radius !important;
}

.language-multiselect {
  .multiselect__tags {
    border: 0 !important;
    background: rgba(255, 255, 255, 0.5) !important;
  }

  .multiselect__input,
  .multiselect__single {
    background: transparent;
  }

  &.custom-vue-multiselect .multiselect__content-wrapper {
    border-radius: $border-radius;
    margin-top: 0.5rem;
    padding: 12px;
    right: 0;
    width: auto;
  }

  &.custom-vue-multiselect .multiselect__option--selected,
  .custom-vue-multiselect .multiselect__option--selected.multiselect__option--highlight {
    background: #f9fafb;
  }

  &.custom-vue-multiselect .multiselect__option--highlight {
    background: #f9fafb;
  }
}

.custom-vue-multiselect-2 {
  .multiselect__tags {
    border-radius: $border-radius;
    padding: 18px 20px 12px;
  }

  .multiselect__single {
    padding-left: 0;
  }

  .option__image {
    @include size(37px);
  }

  .multiselect__select {
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;

    &::before {
      transform: translateY(-50%);
    }
  }

  .multiselect__content-wrapper {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    padding: 1rem;
  }

  .multiselect__option--highlight,
  .multiselect__option--selected.multiselect__option--highlight {
    background-color: var(--bs-light);

    .option__title {
      color: var(--bs-dark);
    }
  }
}

.custom-vue-multiselect-3 {
  &.multiselect--active {
    border-radius: $border-radius;

    .multiselect__tags {
      border-color: var(--bs-dark);
    }
  }

  .multiselect__tags {
    border-radius: $border-radius !important;
    padding: 13px 20px 6px;
    cursor: pointer;
  }

  .multiselect__single {
    padding-left: 0;
    margin-bottom: 0.5rem;
  }

  .option__image {
    @include size(22px);

    img {
      @include size(100%);
    }
  }

  .option__title {
    font-size: 1.2rem;
    font-weight: 500;
  }

  .multiselect__select {
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;

    &::before {
      transform: translateY(-50%);
    }
  }

  .multiselect__option {
    padding: 16px;
  }

  .multiselect__content-wrapper {
    right: 0;
    width: 100%;
    border-radius: $border-radius;
    border: 0;
    padding: 20px;
    box-shadow: 0px 12px 32px rgba(34, 34, 57, 0.08);
  }

  .multiselect__input {
    position: absolute;
    width: 100%;
    border-radius: $border-radius;

    &::placeholder {
      font-size: 1rem;
    }
  }

  .multiselect__option--highlight,
  .multiselect__option--selected.multiselect__option--highlight {
    background-color: var(--bs-light);
    &::after {
      display: none;
    }
    .option__title {
      color: var(--bs-dark);
    }
  }
}

// Table
table.custom-table {
  th {
    font-weight: 500;
    border: 0;

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      padding-left: 2.4rem;
    }

    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      padding-right: 2.4rem;
    }
  }

  tr.no-border-end {
    td {
      border-bottom: 0;
    }

    & + tr.no-border-end {
      td {
        border-top: 1px solid $border-color;
      }
    }
  }

  td {
    vertical-align: center !important;
    border-color: $border-color;

    &:first-child {
      padding-left: 2.4rem;
    }

    &:last-child {
      padding-right: 2.4rem;
    }
  }

  thead {
    background-color: var(--bs-lighter);
    border-radius: $border-radius !important;
    overflow: hidden;
    margin-bottom: 2rem;
  }

  td {
    padding: 1.45rem 0;
  }

  th {
    padding: 1.35rem 0;
  }
}

table.custom-table2 {
  .td-action,
  .th-action {
    width: 10rem;
  }

  th {
    font-weight: 500;
    border: 0;
    padding-left: 0;
    padding-right: 0;
  }

  tr.no-border-end {
    td {
      border-bottom: 0;
    }

    & + tr.no-border-end {
      td {
        border-top: 1px solid $border-color;
      }
    }
  }

  td {
    vertical-align: center !important;
    padding: 2.5rem 0;

    &:not(:first-child) {
      padding-left: 1.2rem;
    }

    &:not(:last-child) {
      padding-right: 1.2rem;
    }
  }

  th {
    &:not(:first-child) {
      padding-left: 1.2rem;
    }

    &:not(:last-child) {
      padding-right: 1.2rem;
    }
  }

  thead {
    overflow: hidden;
    margin-bottom: 2rem;
    border-bottom: 1px solid $border-color;
  }

  th {
    padding-bottom: 2.4rem;
  }
}

//Pagination
.pagination-custom {
  &-circle {
    .page-item {
      border-radius: $border-radius !important;

      &.active {
        border-color: var(--bs-dark) !important;

        a,
        .page-link {
          color: var(--bs-dark) !important;
        }
      }

      &:hover {
        border-color: var(--bs-dark) !important;

        a,
        .page-link {
          color: var(--bs-dark) !important;
        }
      }
    }
  }

  .page-item {
    color: var(--bs-body-color);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;

    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    cursor: pointer;
    @include size(40px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 4px;
    border-radius: $border-radius;
    border: 1px solid $border-color;

    a {
      color: var(--bs-body-color);
    }

    & + .page-item {
      margin-left: 8px;
    }

    &.disabled {
      pointer-events: none;
      user-select: none;

      span {
        color: var(--bs-gray-300);
        border: 0;
        font-size: 2rem;
        @include flex(center, center);
      }
    }
  }

  .page-link {
    background-color: transparent;
    padding: 0;
    font-size: 1.4rem;
    font-weight: 600;
    border: 0;
    @include size(100%);
  }
}

// Accordion
.custom-accordion {
  .accordion-button {
    padding: 0;

    &:not(.collapsed) {
      box-shadow: unset;
    }

    &::after {
      display: none;
    }

    &.collapsed {
      .custom-accordion-icon {
        transform: rotate(0);
        color: var(--bs-gray-light-40) !important;
      }
    }
  }

  &-icon {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    transform: rotate(90deg);
    color: var(--bs-dark) !important;
  }

  &-title {
    width: 90%;
    flex-shrink: 0;
    line-height: 2.8rem;
  }

  .accordion-item {
    border: 0;

    & + .accordion-item {
      margin-top: 3.2rem;
    }
  }
  .accordion-button {
    background-color: $body-background;
  }

  .accordion-body {
    background-color: var(--bs-light);
    border-radius: $border-radius;
    padding: 2rem;
    margin-top: 2rem;

    div {
      text-align: left;
    }
  }
}

// Datepicker
.custom-datepicker {
  height: 4rem;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  @include flex(center, space-between);
  position: relative;

  .calendar-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.6rem;
    //user-select: none;
    //pointer-events: none;
    &:hover {
      .refresh-calendar-btn {
        display: block;
      }
      .calendar-icon-btn {
        display: none;
      }
    }
    .refresh-calendar-btn {
      display: none;
      cursor: pointer;
    }
  }
}

// Tab
.custom-tab {
  .nav-tabs {
    border: 0;
    width: 100%;
    justify-content: space-between;
  }

  .nav-item {
    width: 50%;
  }

  .nav-link {
    padding: 1.6rem 0;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 2px solid transparent;
    width: 100%;

    &:hover {
      color: var(--bs-primary);
      border-color: var(--bs-primary);
    }

    &.active {
      span {
        color: var(--bs-dark);
      }

      background-color: transparent;
      border-color: var(--bs-primary);
      cursor: unset;
      user-select: none;
      pointer-events: none;
    }
  }
}

.tab-block {
  position: relative;
  margin-top: 2.4rem;
  min-height: 37rem;
  &.tab-block-activities {
    overflow: hidden;
    border: 1px solid $border-color;
    border-radius: 2.4rem;
  }
}

.bg-0 {
  background-color: unset !important;
}

// Datepicker
.dp__menu {
  font-family: "inter";
}
.dp__menu.dp__menu_index.dp__theme_light {
  border: 0;
  box-shadow: 0 8px 32px rgba(33, 37, 44, 0.1);
  padding: 1.6rem;
  border-radius: $border-radius;
}

.dp__selection_preview,
.dp__button {
  display: none !important;
}

.dp__calendar_next {
  margin-left: 0 !important;
}

.dp__action_row {
  padding: 0 !important;
  height: 4rem;
}

.dp__cell_inner {
  font-size: 1.4rem;
  font-weight: 600;
  //color: #2B323B;
}

.dp__arrow_top {
  border: 0 !important;
  top: 0 !important;
}

.dp__today {
  border: 0 !important;
  color: var(--bs-primary);
}

.dp__calendar_header_separator {
  background-color: var(--bs-light) !important;
}

.dp__overlay_cell {
  font-size: 1.4rem;
}

.dp__overlay_cell_active {
  font-size: 1.4rem;
  background: var(--bs-primary) !important;
}

.dp__range_start,
.dp__range_end {
  background: var(--bs-dark) !important;
  border-radius: 50% !important;
}

.dp__cell_inner {
  width: 4rem !important;
  height: 4rem !important;
}

.dp__date_hover:hover {
  border-radius: 50%;
}

.dp__month_year_select {
  font-size: 1.6rem;
  font-weight: 500;
}

.dp__calendar_header_item {
  font-size: 1.3rem;
  font-weight: 500;
}

.dp__action_buttons {
  position: absolute;
  left: 50%;
  width: auto !important;
  display: flex;
  transform: translateX(-50%);
  text-align: center !important;
}

.dp__action {
  border: 0;
  width: fit-content;
  border-radius: $btn-radius;
  padding: 0 1.6rem;
  min-width: $button-min-width;
  height: $button-height;
  display: block;
  line-height: 1.6rem;
  cursor: pointer;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;

  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  @include flex(center, center);
  font-size: 1.4rem;

  &.dp__cancel {
    background-color: #f4f5f7;
    color: var(--bs-body-color);
    margin-right: 0.6rem;
    font-family: "inter";
    font-weight: 500;
  }

  &.dp__select {
    background-color: var(--bs-primary);
    color: #fff;
    margin-left: 0.6rem;
    font-family: "inter";
    font-weight: 500;
  }
}

.custom-datepicker {
  .dp__icon {
    display: none !important;
  }

  .dp__main.dp__theme_light {
    width: 100%;
  }

  .dp__input {
    border-radius: $border-radius;
    height: 100%;
    width: 100%;
    border: 0;
    padding: 1.1rem 1.6rem;
    font-size: 1.2rem;
    color: #2b323b !important;
    font-weight: 600;
    font-family: "inter";

    &::placeholder {
      color: var(--bs-body-color) !important;
      font-weight: 600;
    }
  }
}

// Perfect Scrollbar
.ps__rail-y {
  opacity: 1 !important;

  .ps__thumb-y {
    width: 4px !important;
  }
}

// Tooltip
.v-popper--theme-tooltip .v-popper__inner {
  font-size: 1.2rem !important;
  padding: 5px 10px 4px;
  background: rgba(1, 16, 47, 0.8) !important;
}
.v-popper--theme-tooltip .v-popper__arrow-outer {
  border-color: rgba(1, 16, 47, 0.8) !important;
}
.v-popper--theme-tooltip {
  z-index: 999999 !important;
}
.break {
  white-space: break-spaces;
  overflow-wrap: break-word;
}
.custom-css-des {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 3px; /* Độ rộng của thanh cuộn */
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Màu nền của track */
  }
  &::-webkit-scrollbar-thumb {
    background: #888; /* Màu của thumb */
    border-radius: 5px; /* Bo tròn góc của thumb */
  }
  span {
    white-space: break-spaces;
    overflow-wrap: break-word;
  }
}
