#header {
  background-color: var(--bs-dark);
  height: $header-height;
}

%divideLeftMenu {
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 32px;
    width: 1px;
    background-color: $c-gray;
  }
}

.header {
  /*&-content {
      padding: 24px 0;
  }*/

  &-menu {
    @extend %divideLeftMenu;
    height: 100%;
    margin-left: 20px;
    padding-left: 10px;

    &-right {
      height: 100%;
      flex-shrink: 0;
      padding-left: 1.6rem;
      @extend %divideLeftMenu;
    }
  }
}
.login-btn {
  width: 13.7rem;
}
.count {
  position: absolute;
  top: 6px;
  right: 0px;
  background-color: red;
  font-weight: bold;
  font-size: 10px !important;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
 
  @media screen and (max-width: 768px) {
    font-size: 10px !important;
  }
}
